import {useEffect, useState} from "react";
import {countdownTimer} from "../../utils/countdownTimer";
import {useTranslation} from "react-i18next";


const EndDateTimer = ({endDate, currentDate, finalMessage='Время истекло!'}) => {
	const {i18n} = useTranslation();
	const [subscriptionTimer, setSubscriptionTimer] = useState({
		message: '',
		intervalLink: null,
	});

	useEffect(() => {
		subscriptionTimer?.intervalLink && clearInterval(subscriptionTimer?.intervalLink);

		if (endDate && currentDate){
			countdownTimer({
				endDate: new Date(endDate),
				currentDate: new Date(currentDate),
				finalMessage: finalMessage,
				setter: setSubscriptionTimer,
				locale: i18n.language,
			});
		}

		return () => {
			subscriptionTimer?.intervalLink && clearInterval(subscriptionTimer?.intervalLink);
		};
	}, []);

	if (!endDate || !currentDate) return null;

	return subscriptionTimer.message;
};

export default EndDateTimer;