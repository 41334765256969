import React, { useEffect, useState, useRef } from "react";
import PageHeader from "../../components/Header/PageHeader";
import BottomNav from "./BottomNavContentPlan";
import { useDispatch, useSelector } from "react-redux";
import instagramContentIcon from "../icons/instagram-content.svg"; // Replace with path to your Google icon
import facebookContentIcon from "../icons/facebook-content.svg"; // Replace with path to your Google icon
import linkedInContentIcon from "../icons/linkedin-content.svg"; // Replace with path to your Google icon
import emptyContentPlanIcon from "../icons/empty-content-plan.svg"; // Replace with path to your Google icon
import DropdownContent from "./DropdownContent";
import { contentPlanActions } from "../../store";
import ContentPlanDrawer from "./ContentPlanDrawer";
// import { forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { message, notification, Space, Button } from "antd";
import {jwtDecode} from "jwt-decode";
import {fetchWrapper} from "../../helpers";

const icons = [instagramContentIcon, facebookContentIcon, linkedInContentIcon];
const colors = ["#EEEBFF", "#FFEBF9", "#EBF0FF"];
const baseUrl = `${process.env.REACT_APP_API_URL}`;
const generateLangs = [
  {
    id: 1,
    label: {
      ru: "на русском языке",
      en: "in russian language",
    },
    value: "на русском языке",
  },
  {
    id: 2,
    label: {
      ru: "на английском языке",
      en: "in english language"
    },
    value: "in english language",
  }
];
export const CreateContentPlan = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    socialNetworks,
    contentPlans,
    weeks,
    loading,
    generating,
    loadingCreate,
  } = useSelector((obj) => obj.contentPlan);
  const [activeWeek, setActiveWeek] = useState(weeks[0]);
  const [hasContentPlanForWeek, setHasContentPlanForWeek] = useState();
  const [activeContentPlans, setActiveContentPlans] = useState({});
  const [visibleOpenModal, setVisibleModal] = useState(false);
  const interval = useRef(null);
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();
  const user = useSelector((obj) => obj.auth.user);
  const [token, setToken] = useState("")
  const blocks = useSelector((obj) => obj.strategy.blocks);
  const [localUser, setLocalUser] = useState(
      JSON.parse(localStorage.getItem("user"))
  );
  const [generateLang, setGenerateLang] = useState(i18n.language === 'ru'? generateLangs[0].value : generateLangs[1].value);

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="primary"
          size="small"
          onClick={() => navigate("/subscription-plans")}
        >
          {t("posts.goToRate")}
        </Button>
      </Space>
    );
    notificationApi.open({
      message: t("functions.error"),
      description: t("posts.limit"),
      btn,
      key,
    });
  };

  const generateContentPlan = async (values) => {
    message.loading(t("functions.generating"));
    await Promise.all(
      values.socialNetworks.map(async (sn) =>
        dispatch(
          contentPlanActions.createContentPlan({
            socialNetwork: sn,
            content_blocks: values.contentBlocks,
            frequency: values.frequency,
            timezone: -new Date().getTimezoneOffset(),
            lang: generateLang,
            week: activeWeek,
          })
        )
      )
    )
      .then(() => {
        message.success(t("functions.successfullySendToGenerate"));
      })
      .catch((err) => {
        message.error(t("functions.wrong"));
        openNotification();
      });
    setVisibleModal(false);
  };

  const openNotificationLinkedIn = () => {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
          <Button type="primary" size="small" onClick={() => navigate("/profile")}>
            {t("profile.linkAccount")}
          </Button>
        </Space>
    );
    notificationApi.open({
      message: t("contentPlan.tokenError"),
      btn,
      key,
    });
  };

  const handleGenerateContentPlan = () => {
    if(token === ""){
      openNotificationLinkedIn()
    }else{
      generateContentPlan({
        contentBlocks: blocks,
        socialNetworks: ["LinkedIn"],
        frequency: 7,
      })
    }
  }

  useEffect(() => {
    setActiveWeek(weeks.length);
  }, [weeks]);

  useEffect(() => {
    if (generating) {
      interval.current = setInterval(() => {
        dispatch(contentPlanActions.getContentPlan(socialNetworks));
      }, 5000);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [dispatch, generating, interval]);

  // useEffect(() => {
  //   if (socket) {
  //     socket.on('finishedContentPlan', () => {
  //       dispatch(contentPlanActions.getContentPlan(socialNetworks))
  //     })
  //   }

  // }, [socket])

  useEffect(() => {
    dispatch(contentPlanActions.getContentPlan(socialNetworks));
    fetchWrapper.get(`${baseUrl}/auth/getSocialMediaTokens/`).then(res => {
      res.forEach((t) => {
        if (t.socialMediaType == "LINKEDIN") {
          setToken(t.accessToken)
        }
      })
    })
  }, []);

  useEffect(() => {
    let tempHasCn = false;
    const tempCn = {};
    for (const sn of socialNetworks) {
      const filteredCn = contentPlans[sn.fetch].filter(
        (cn) => cn.week == activeWeek
      );
      if (filteredCn.length > 0) {
        tempHasCn = true;
      }
      tempCn[sn.fetch] = filteredCn;
    }
    setHasContentPlanForWeek(tempHasCn);
    setActiveContentPlans(tempCn);
  }, [activeWeek, contentPlans, loading, socialNetworks]);

  const isEmpty = !loading && !hasContentPlanForWeek;

  return (
    <>
      {notificationContextHolder}
      <PageHeader
        title={t("contentPlan.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />
      {generating ? (
        <div>
          <h1 className="self-assessment-title">
            {t("functions.generatingContentPlan")}
          </h1>
        </div>
      ) : (
        <>
          <div>
            <h1 className="self-assessment-title">
              {t("contentPlan.week")} {activeWeek}{" "}
              {activeContentPlans[socialNetworks[2]?.fetch]?.length > 0
                ? "(" +
                  (moment(
                    activeContentPlans[socialNetworks[2]?.fetch]?.[0]?.date
                  ).format("DD/MM/YYYY") +
                    " - " +
                    moment(
                      activeContentPlans[socialNetworks[2]?.fetch]?.[
                        activeContentPlans[socialNetworks[2]?.fetch]?.length - 1
                      ]?.date
                    ).format("DD/MM/YYYY")) +
                  ")"
                : null}
            </h1>
          </div>

          {localUser.strategiesCount < 5 ? (
              <div className="flex flex-col items-center justify-center">
                <h3 className='text-xl text-center font-semibold mb-10'>
                  {t("contentPlan.needSurveyText")}
                </h3>
                <button
                  className="logout-button mx-auto max-w-max max-w-fit"
                  onClick={() => navigate("/survey")}
                >
                  {t("contentPlan.goToSurveyBtnText")}
                </button>
              </div>
              ):
              activeWeek === 3 && localUser.strategiesCount < 15 ? (
                      <div className="flex flex-col items-center justify-center">
                        <h3 className='text-xl text-center font-semibold mb-10'>
                          {t("contentPlan.needSurveyText")}
                        </h3>
                        <button
                            className="logout-button mx-auto max-w-max max-w-fit"
                            onClick={() => navigate("/personality-survey")}
                        >
                          {t("contentPlan.goToSurveyBtnText")}
                        </button>
                      </div>
              ):
            isEmpty ? (
                <div className="flex flex-col items-center justify-center">
                  <button
                      className="logout-button max-w-fit max-w-max"
                      // className="survey-button"
                      // onClick={() => setVisibleModal(true)}
                      onClick={handleGenerateContentPlan}
                  >
                    {t("contentPlan.create")}
                  </button>
                  <select
                    className='border rounded-lg p-2.5 mt-2.5 text-center no-arrows cursor-pointer hidden'
                    value={generateLang}
                    onChange={e=>setGenerateLang(e.target.value)}
                  >
                    {generateLangs.map((lng) => (
                        <option key={lng.id} value={lng.value}>{lng.label[i18n.language]}</option>
                    ))}
                  </select>
                  <img src={emptyContentPlanIcon}/>
                </div>
            ) : (
                <DropdownContent
                    key={2}
                    color={colors[2]}
                    icon={icons[2]}
                    items={activeContentPlans[socialNetworks[2].fetch]}
            />
          )}
          <div style={{ height: 70 }}></div>
          <BottomNav
            // onClickFont={() => setVisibleModal(true)}
            onClick={() =>
              generateContentPlan({
                contentBlocks: blocks,
                socialNetworks: ["LinkedIn"],
                frequency: 7,
              })
            }
            isFont={hasContentPlanForWeek}
            onClickBefore={() =>
              activeWeek > 1 && setActiveWeek(activeWeek - 1)
            }
            onClickNext={() => setActiveWeek(activeWeek + 1)}
            onClickFont={() => setVisibleModal(true)}
          />
          <ContentPlanDrawer
            loadingCreate={loadingCreate}
            onOk={handleGenerateContentPlan}
            open={visibleOpenModal}
            close={() => setVisibleModal(false)}
            activeWeek={activeWeek}
          />
        </>
      )}
    </>
  );
};
