import {fetchWrapper} from "../helpers";


export function useInviteTokenInterceptor(baseUrl) {
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');
	const beWoman = urlParams.get('beWoman');
	const user = localStorage.getItem('user');

	if (token) {
		localStorage.setItem('inviteToken', token);
	}
	if (beWoman) {
		localStorage.setItem('beWoman', beWoman);
	}

	const inviteToken = localStorage.getItem('inviteToken');
	const beWomanToken = localStorage.getItem('beWoman');

	if (inviteToken && user){
		fetchWrapper.post(`${baseUrl}/invitation/validate`, {token: inviteToken})
				.then((res)=>{
					if (res){
						localStorage.removeItem('inviteToken');
					}
				}).catch((err)=>{ console.error(err) })
	}
	if (beWomanToken && user){
		fetchWrapper.post(`${baseUrl}/invitation/validate`, {token: beWomanToken})
				.then((res)=>{
					if (res){
						localStorage.removeItem('beWomanToken');
					}
				}).catch((err)=>{ console.error(err) })
	}
}