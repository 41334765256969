import React from 'react';
import { useTranslation } from "react-i18next";
import GoalsForm from "../../components/Forms/GoalsForm";
import PageHeader from "../../components/Header/PageHeader";
import {useNavigate} from "react-router-dom";

const Goals = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
			<>
				<PageHeader
						title={t("survey.survey")}
						onBackClick={() => {
							navigate("/home");
						}}
				/>
				<div>
					<h1 className="self-assessment-title">
						{t("survey.title")}
					</h1>
				</div>
				<div className="survey-page pb-5">
					<div className="navbar">
						<div className="menu-icon"></div>
						<div className="title">{t("survey.survey")}</div>
					</div>

					<GoalsForm/>
				</div>
			</>
	);
};

export default Goals;