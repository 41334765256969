import React, { useEffect, useState } from "react";
import "./HomePage.css"; // Make sure to create a corresponding CSS file
import crownIcon from "../icons/crown.png";
import profileImage from "./icons/profile-home-img.png";
import assistantImage from "./icons/assistant-home-img.png";
import personalBrandStrategy from "./icons/strategy-home-img.png";
import contentPlanImage from "./icons/content-plan-home-img.png";
import publicationImage from "./icons/publication-home-img.png";
import createContent from "./icons/create-content.svg";
import createContentText from "./icons/create-content-text.svg";
import createPost from "./icons/create-post.svg";
import firstPlan from "./icons/first-plan.svg";
import secondPlan from "./icons/second-plan.svg";
import facebookIcon from "./icons/facebook-icon.svg";
import instagramIcon from "./icons/instagram-icon.svg";
import manImage from "./icons/man.png";
import {Link, useNavigate} from "react-router-dom";
import { authActions, strategiesActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { Tooltip } from "antd";
import { fetchWrapper } from "../../helpers";
import axios from "axios";
import {getUserTimeInfo} from "../../helpers/get-user-time-zone";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((obj) => obj.auth.user);
  const [sid, setSid] = useState(
      jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken)
          .subscriptionLevel.id
  );
  const [localUser, setLocalUser] = useState(
      JSON.parse(localStorage.getItem("user"))
  );

  const [subDaysLeft, setSubDaysLeft] = useState(
    Math.round(
      moment
        .duration(
          moment(
            jwtDecode(user?.accessToken)?.userSubscription?.endDate ||
              moment().subtract(1, "M").startOf("day")
          ) - moment().startOf("day")
        )
        .asDays()
    )
  );

  const refreshToken = () => {
    fetchWrapper.get(`${baseUrl}/auth/refresh-token`).then((res) => {
      localStorage.setItem("user", JSON.stringify(res));
      setSubDaysLeft(
        Math.round(
          moment
            .duration(
              moment(
                jwtDecode(res?.accessToken)?.userSubscription?.endDate ||
                  moment().subtract(1, "M").startOf("day")
              ) - moment().startOf("day")
            )
            .asDays()
        )
      );
      dispatch(authActions.updateUser());
    });
  };

  useEffect(() => {
    refreshToken();
  }, []);

  // IMEAN
  // useEffect(() => {
  //   fetchWrapper.get('https://my.imean.io/api/strategy/actual/')
  //       .then((res)=>{
  //         console.log(res)
  //       }).catch((err)=>{
  //         console.log(err)
  //       })
  // }, []);

  return (
      <div className="home-page">
        <div className="menu-icon"></div>
        <div className="head-container items-center">
          <div className="welcome-text">
            {t("home.welcomeBack")}
            {user?.name || ""}!
            {!!sid ? <img className='premium-icon' src={`${crownIcon}`} alt="premium"/> : null}
          </div>
          <button
              id="logout-desktop"
              className="generate-button"
              onClick={() => dispatch(authActions.logout())}
          >
            {t("home.logout")}
          </button>
        </div>
        <div className="card-container">
          {/*{subDaysLeft >= 0 && (*/}
          {/*  <div className="card create-post">*/}
          {/*    <Tooltip*/}
          {/*      title={t("home.subscriptionDaysLeft", {*/}
          {/*        days: subDaysLeft === 0 ? 1 : subDaysLeft,*/}
          {/*      })}*/}
          {/*    >*/}
          {/*      <div className="home-subscription-counter">*/}
          {/*        {subDaysLeft === 0 ? 1 : subDaysLeft}*/}
          {/*      </div>*/}
          {/*    </Tooltip>*/}
          {/*  </div>*/}
          {/*)}*/}

          <Link to="/profile" title={t("home.profileTitle")}
                className='group text-center text-primary font-semibold mx-2 my-7'>
            <img className='-mb-8 group-hover:scale-110 transition-transform max-w-36 h-auto mx-auto object-contain' src={profileImage} alt={t("home.profile")}/>
            {t("home.profile")}
          </Link>

          <Link to="/content-plan" title={t("home.contentPlanTitle")}
                className='group text-center text-primary font-semibold mx-2 my-7'>
            <img className='-mb-1.5 group-hover:scale-110 transition-transform max-w-36 h-auto mx-auto object-contain' src={contentPlanImage} alt={t("home.contentPlan")}/>
            {t("home.contentPlan")}
          </Link>

          <Link to="/posts" title={t("home.createPostTitle")}
                className='group text-center text-primary font-semibold mx-2 my-7'>
            <img className='-mb-1.5 group-hover:scale-110 transition-transform max-w-36 h-auto mx-auto object-contain' src={publicationImage} alt={t("home.createPost")}/>
            {t("home.createPost")}
          </Link>

          <Link to="/assistant" title={t("home.assistantTitle")}
                className='group text-center text-primary font-semibold mx-2 my-7'>
            <img className='-mb-1.5 group-hover:scale-110 transition-transform max-w-36 h-auto mx-auto object-contain' src={assistantImage}
                 alt={t("home.personalAssistant")}/>
            {t("home.personalAssistant")}
          </Link>

          {localUser.strategiesCount > 10 && (
              <Link to="/personal-brand" title={t("home.strategyTitle")}
                    className='group text-center text-primary font-semibold mx-2 my-7'>
                <img className='-mb-1.5 group-hover:scale-110 transition-transform max-w-36 h-auto mx-auto object-contain' src={personalBrandStrategy}
                     alt={t("home.strategy")}/>
                {t("home.strategy")}
              </Link>
          )}

          {/* <div className="schedule-container">
          <div className="schedule-item-home facebook">
          <img src={facebookIcon} style={{zIndex:1, top: 0, left:20}} />
            <div className="schedule-time">10:00 AM</div>
            <div className="schedule-label">Post</div>
            <div className="schedule-description">Weekly forex market analysis with a focus on key currency trends.</div>
            <div className="schedule-action"></div>
              <img src={firstPlan} style={{zIndex:0}} />
          </div>
          
          <div className="schedule-item-home instagram">
              <img src={instagramIcon} style={{zIndex:1, top: 0, left:20}}/>
            <div className="schedule-time">1:00 PM</div>
            <div className="schedule-label">Post</div>
            <div className="schedule-description">In-depth analysis of a recent global economic event impacting forex markets.</div>
            <div className="schedule-action"></div>
              <img src={secondPlan}  style={{zIndex:0}}/>
          </div>
        </div> */}

        </div>

        <button
            id="logout-mobile"
            className="logout-button"
            onClick={() => dispatch(authActions.logout())}
        >
          {t("home.logout")}
        </button>

        <div className='my-7 flex flex-col gap-2.5'>
          <button
              className="support-button"
              onClick={() => navigate('/guideline')}
          >
            {t("home.guideline")}
          </button>
          <button
              className="support-button"
              onClick={() => navigate('/support')}
          >
            {t("home.support")}
          </button>
        </div>

      </div>
  );
};

export default HomePage;
