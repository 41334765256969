import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React from "react";
import "./SideNav.css";
import { fetchWrapper } from "../../helpers";
import {useSelector} from "react-redux";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const SideNav = ({ items, isVisible, onClick, toogleNav }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((obj) => obj.auth.user);

  const generatePdf = () => {
    fetch(`${baseUrl}/strategy/generate-pdf/`, {
      method: "GET",
      headers: {
        accessToken: `${JSON.parse(localStorage.getItem("user")).accessToken}`,
      },
      responseType: "blob",
    }).then((res) => {
      res.blob().then((blob) => {
        const file = new Blob([blob], { type: "application/pdf" });

        // process to auto download it
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${user.name.replace(' ', '_').replace(' ', '_')}_Strategy_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.pdf`;
        link.click();
      });
    });
  };

  return (
    <aside className={"side-nav " + (isVisible ? "visible" : "")}>
      <nav className="nav-menu">
        {items.map((item, index) => (
          <a onClick={() => onClick(index)} key={index} className="nav-item">
            {item}
          </a>
        ))}
      </nav>
      <button
        className="personal-brand-button"
        style={{ marginBottom: "15px" }}
        onClick={() => {
          navigate("/goals-survey");
        }}
      >
        {t("personalBrand.newStrategy")}
      </button>
      <button
        className="personal-brand-button"
        style={{ marginBottom: "15px", background: "red" }}
        onClick={() => {
          generatePdf();
        }}
      >
        {t("personalBrand.pdf")}
      </button>
    </aside>
  );
};

export default SideNav;
