import React, { useState, useEffect, useRef } from "react";
import "../Survey/SurveyPage.css";
import activeMic from "../icons/mic-on.svg";
import inactiveMic from "../icons/mic-off.svg";
import QuestionCarousel from "../../components/Carousel/QuestionCarousel";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { contentPlanActions, postActions, questionsActions } from "../../store";
import { message, notification, Space, Button } from "antd";
import PageHeader from "../../components/Header/PageHeader";
import { PostPage } from "./PostsPage";
import { CreatePost } from "./CreatePost";
import { useTranslation } from "react-i18next";

export const Post = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const contentPlan = useSelector((obj) => obj.contentPlan.contentPlan);
  const post = useSelector((obj) => obj.post.post);
  const contentPlanLoading = useSelector((obj) => obj.contentPlan.loading);
  const postLoading = useSelector((obj) => obj.post.loading);
  const generating = useSelector((obj) => obj.post.generating);
  const interval = useRef(null);
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(contentPlanActions.getContentPlanById(params.contentId));
    dispatch(postActions.getPost(params.contentId));
  }, [dispatch]);

  useEffect(() => {
    if (generating) {
      interval.current = setInterval(() => {
        dispatch(contentPlanActions.getContentPlanById(params.contentId));
        dispatch(postActions.getPost(params.contentId));
      }, 5000);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [dispatch, generating, interval]);

  //   useEffect(() => {
  //     if (socket) {
  //       socket.on("finishedPost", () => {
  //         dispatch(contentPlanActions.getContentPlanById(params.contentId));
  //         dispatch(postActions.getPost(params.contentId));
  //       });
  //     }
  //   }, [dispatch, params.contentId, socket]);

  const isLoadingFinished = !contentPlanLoading & !postLoading;

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="primary"
          size="small"
          onClick={() => navigate("/subscription-plans")}
        >
          {t("posts.goToRate")}
        </Button>
      </Space>
    );
    notificationApi.open({
      message: t("functions.error"),
      description: t("posts.limit"),
      btn,
      key,
    });
  };

  return (
    <>
      {notificationContextHolder}
      <div>
        <PageHeader
          title={post ? t("post.headerPost") : t("post.headerCreate")}
          onBackClick={() => {
            if (contentPlan) {
              if (contentPlan.forIndependentPosts) {
                navigate("/posts");
                window.location.reload();
              } else {
                navigate("/content-plan");
                window.location.reload();
              }
            }
          }}
        />
        {contentPlan && (
          <div>
            <p className="self-assessment-title" style={{ fontSize: "1rem" }}>
              {contentPlan.description}
            </p>
          </div>
        )}
        {generating ? (
          <div style={{ textAlign: "center" }}>{t("post.generating")}...</div>
        ) : isLoadingFinished ? (
          post ? (
            <PostPage />
          ) : (
            <CreatePost openNotification={openNotification} />
          )
        ) : (
          <div style={{ textAlign: "center" }}>{t("functions.loading")}</div>
        )}
      </div>
    </>
  );
};
