import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {fetchWrapper} from "../../helpers";
import {message} from "antd";
import {mergeStateObject} from "../../utils/merger";

const formDefaultValues = {
	shortTermGoals: '',
	longTermGoals: '',
	// unique_value_proposition: '',
	awardsAndAchievements: '',
	currentProjects: '',
	strengths: '',
	weaknesses: '',
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const GoalsForm = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [surveyForm, setSurveyForm] = useState(formDefaultValues);
	const handleSubmitForm = (e) => {
		e.preventDefault();
		fetchWrapper.patch(`${baseUrl}/user-profile`, surveyForm).then(async (response)  => {
			if (response.message) {
				message.success(t("functions.successfullyGenerated"));
				// navigate("/personal-brand");
				navigate("/home");
				await fetchWrapper.post(`${baseUrl}/strategy/generate`);
				await fetchWrapper.get(`${baseUrl}/strategy/actual`);
			}
		}).catch((err)=>{
			console.error(err)
			message.error(t("functions.wrong"));
		});
	}

	const handleResetForm = ()=>{
		setSurveyForm(formDefaultValues)
	}

	return (
			<form onSubmit={handleSubmitForm}>

				<div className="question">
					<div className='my-5'>
						<label className='font-semibold '
									 htmlFor="shortTermGoals">{t('survey.textLabelShortGoals')}</label>
						<textarea
								required
								id='shortTermGoals'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderShortGoals")}
								value={surveyForm.shortTermGoals}
								onChange={e => mergeStateObject(setSurveyForm, {shortTermGoals: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

					<div className='my-5'>
						<label className='font-semibold ' htmlFor="longTermGoals">{t('survey.textLabelLongGoals')}</label>
						<textarea
								required
								id='longTermGoals'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderLongGoals")}
								value={surveyForm.longTermGoals}
								onChange={e => mergeStateObject(setSurveyForm, {longTermGoals: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

					{/*<div className='my-5'>*/}
					{/*	<label className='font-semibold ' htmlFor="unique_value_proposition">{t('survey.textLabelUniqueTradeRelations')}</label>*/}
					{/*	<textarea*/}
					{/*			required*/}
					{/*			id='unique_value_proposition'*/}
					{/*			className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'*/}
					{/*			placeholder={t("survey.textPlaceholderUniqueTradeRelations")}*/}
					{/*			value={surveyForm.unique_value_proposition}*/}
					{/*			onChange={e => mergeStateObject(setSurveyForm, {unique_value_proposition: e.target.value})}*/}
					{/*	/>*/}
					{/*</div>*/}

					<div className='my-5'>
						<label className='font-semibold ' htmlFor="awardsAndAchievements">{t('survey.textLabelAchievements')}</label>
						<textarea
								required
								id='awardsAndAchievements'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderAchievements")}
								value={surveyForm.awardsAndAchievements}
								onChange={e => mergeStateObject(setSurveyForm, {awardsAndAchievements: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

					<div className='my-5'>
						<label className='font-semibold ' htmlFor="currentProjects">{t('survey.textLabelProjects')}</label>
						<textarea
								required
								id='currentProjects'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderProjects")}
								value={surveyForm.currentProjects}
								onChange={e => mergeStateObject(setSurveyForm, {currentProjects: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

					<div className='my-5'>
						<label className='font-semibold ' htmlFor="strengths">{t('survey.textLabelStrongSides')}</label>
						<textarea
								required
								id='strengths'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderStrongSides")}
								value={surveyForm.strengths}
								onChange={e => mergeStateObject(setSurveyForm, {strengths: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

					<div className='my-5'>
						<label className='font-semibold ' htmlFor="weaknesses">{t('survey.textLabelWeakSides')}</label>
						<textarea
								required
								id='weaknesses'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderWeakSides")}
								value={surveyForm.weaknesses}
								onChange={e => mergeStateObject(setSurveyForm, {weaknesses: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

				</div>

				<div className='flex justify-center items-center'>
					<button
							type='reset'
							className="clear-button"
							onClick={handleResetForm}
					>
						{t("survey.clear")}
					</button>
					<button
							type='submit'
							className="submit-button"
					>
						{t("survey.submit")}
					</button>
				</div>
			</form>
	);
};

export default GoalsForm;