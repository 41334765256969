import {Button, Modal} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";


const TimeZoneModal = () => {
	const {t} = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleModal = () => {
		setIsModalOpen(p => !p);
	};
	return (
			<div>
				<Modal
						title={t("profile.timeZoneModalTitle")}
						open={isModalOpen}
						onOk={toggleModal}
						onCancel={toggleModal}
						footer={[
							<Button key="submit" onClick={toggleModal} type="primary">
								{t("subscriptionPlans.submit")}
							</Button>,
						]}
				>
					<p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={ {__html: t("profile.timeZoneModalText")}}/>
				</Modal>
				<span onClick={toggleModal} className='flex justify-center items-center cursor-help bg-gray-300 hover:bg-gray-200 text-white h-5 w-5 rounded-full'>
						?
					</span>
			</div>
	);
};

export default TimeZoneModal;