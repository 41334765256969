import React from "react";
import PageHeader from "../../components/Header/PageHeader";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import AssistantChat from "../../components/Chats/AssistantChat";


const AssistantPage = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	return (
			<div className='min-h-96 h-[calc(100dvh-250px)]'>

				<PageHeader
						title={t("assistant.header")}
						onBackClick={() => {
							navigate("/home");
						}}
				/>
				<div>
					<h1 className="self-assessment-title">
						{t("assistant.title")}
					</h1>
				</div>

				<AssistantChat/>

			</div>
	);
};

export default AssistantPage;