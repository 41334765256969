import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/Header/PageHeader";
import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import payment_ru from "../../files/payment_ru.pdf";
import payment_en from "../../files/payment_en.pdf";
import Public_Offer_IdeaSocial_ru from "../../files/Public_Offer_IdeaSocial_ru.pdf";
import Public_Offer_IdeaSocial_en from "../../files/Public_Offer_IdeaSocial_en.pdf";
import privacy_ru from "../../files/Privacy_Policy_IdeaSocial_ru.pdf";
import privacy_en from "../../files/Privacy_Policy_IdeaSocial_en.pdf";
import {Document, Page, pdfjs} from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc =
		"https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs";
const options = {
	cMapUrl: '/cmaps/',
	standardFontDataUrl: '/standard_fonts/',
};
const maxWidth = 800;

const PdfViewer = () => {
	// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
	const { document } = useParams();
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const documents = {
		payment: {
			title: t("signin.payment"),
			ru: payment_ru,
			en: payment_en,
		},
		terms: {
			title: t("signin.offerTitle"),
			ru: Public_Offer_IdeaSocial_ru,
			en: Public_Offer_IdeaSocial_en,
		},
		privacy: {
			title: t("signin.privacyTitle"),
			ru: privacy_ru,
			en: privacy_en,
		},
	};
	const [file, setFile] = useState(documents[document][i18n.language]);
	const [numPages, setNumPages] = useState();
	const [containerWidth, setContainerWidth] = useState();
	// const [fileUrl, setFileUrl] = useState(URL.createObjectURL(new Blob([documents[document][i18n.language]], {type: "application/pdf"})));

	const onResize = useCallback((entries) => {
		const [entry] = entries;

		if (entry) {
			setContainerWidth(entry.contentRect.width);
		}
	}, []);

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages);
	}

	// useEffect(() => {
	// 	setFileUrl(documents[document][i18n.language]);
	// }, []);

	return (
			<div>
				<PageHeader title={documents[document]?.title}/>
				<div className='max-w-[800px] mx-auto'>

				<Document className='mx-auto' file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
					{Array.from(new Array(numPages), (_el, index) => (
							<Page
									className='mx-auto'
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
							/>
					))}
				</Document>
				</div>
			</div>
	);
};

export default PdfViewer;
