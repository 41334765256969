import {
  FACEBOOK_AUTH_URL,
  FACEBOOK_SCOPE,
  GOOGLE_AUTH_URL,
  GOOGLE_SCOPE,
  INSTAGRAM_AUTH_URL,
  INSTAGRAM_SCOPE,
  LINKEDIN_AUTH_URL,
  LINKEDIN_SCOPE,
  PROVIDER,
} from "../constants/auth";

const homeUrl = process.env.REACT_APP_BASE_DOMAIN;

const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `${base}?${query}`;
};

export const getRedirectUri = (provider) => {
  return `${homeUrl}/oauth/?provider=${provider.toLowerCase()}`;
};

export const getProvidersUrls = () => ({
  [PROVIDER.GOOGLE]: getURLWithQueryParams(GOOGLE_AUTH_URL, {
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: getRedirectUri(PROVIDER.GOOGLE),
    scope: GOOGLE_SCOPE,
    response_type: "code",
    access_type: "offline",
    prompt: "consent",
  }),
  [PROVIDER.FACEBOOK]: getURLWithQueryParams(FACEBOOK_AUTH_URL, {
    client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
    redirect_uri: getRedirectUri(PROVIDER.FACEBOOK),
    scope: FACEBOOK_SCOPE,
    response_type: "code",
    auth_type: "rerequest",
    display: "popup",
    config_id: "962826258573936",
  }),
  [PROVIDER.LINKEDIN]: getURLWithQueryParams(LINKEDIN_AUTH_URL, {
    response_type: "code",
    client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirect_uri: getRedirectUri(PROVIDER.LINKEDIN),
    scope: LINKEDIN_SCOPE,
  }),
  [PROVIDER.INSTAGRAM]: getURLWithQueryParams(FACEBOOK_AUTH_URL, {
    client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
    redirect_uri: getRedirectUri(PROVIDER.INSTAGRAM),
    scope: INSTAGRAM_SCOPE,
    response_type: "code",
    auth_type: "rerequest",
    display: "popup",
    config_id: "962826258573936",
  }),
});
