export default {
    "signin": {
        "signIn": "Войти",
        "welcomeBack": "Добро пожаловать !",
        "introText": "IMean: твой умный помощник в социальных сетях.",
        "emailLabel": "Адрес электронной почты",
        "inputPassword": "Введите пароль",
        "passwordLabel": "Пароль",
        "forgotPassword": "Забыли пароль ?",
        "dontHaveAccount": "У вас нет аккаунта?",
        "or": "или",
        "continue": "Продолжить",
        "signInGoogle": "Войти через Google",
        "signInApple": "Войти через Apple",
        "signInFacebook": "Войти с помощью Facebook",
        "signInLinkedIn": "Войдите с помощью LinkedIn",
        "linkFacebook": "Привязать аккаунт Facebook",
        "linkLinkedIn": "Привязать аккаунт LinkedIn",
        "linkInstagram": "Привязать аккаунт Instagram",
        "linkedFacebook": "Facebook привязан",
        "unlinkedLinkedIn": "Отвязать LinkedIn",
        "linkedInstagram": "Instagram привязан",
        "privacy": "Условия <offerLink>публичной оферты</offerLink> и <privacyLink>политики</privacyLink> мною прочитаны полностью, все условия мне понятны, со всеми условиями я согласен",
        "payment": "Порядок оплаты",
        "contacts": 'WhatsApp: +7 700 600 0001 \r\n Email: info@imean.io',
        "agreePrivacy": "Выберите, пожалуйста, “☑” галочку напротив текста касательно ознакомления и согласия с Публичной офертой и Политикой.",
        "privacyTitle": "Политика конфиденциальности",
        "offerTitle": "Публичная оферта",
        "chooseLang": "Выберите удобный для себя язык"
    },
    "signup": {
        "signUp": "Зарегистрироваться",
        "fullName": "Полное имя",
        "passwordRequirements": "Убедитесь, что ваш пароль содержит не менее 8 символов: 1 заглавную, 1 строчную, 1 цифру и 1 специальный символ.",
        "haveAccount": "У вас уже есть аккаунт?",
        "signUpGoogle": "Зарегистрируйтесь через Google",
        "signUpApple": "Зарегистрируйтесь через Apple",
        "signUpFacebook": "Зарегистрируйтесь через Facebook",
        "signUpLinkedIn": "Зарегистрируйтесь через LinkedIn",
        "lang": "Язык"
    },
    "welcome": {
      "welcome": "Добро пожаловать,{{username}}, в IMean!",
      "howWeWork": "Как мы работаем",
      "completeSurvey": "Создание персонализированных постов \n начинается с четко продуманной стратегии.",
      "completeSurvey2": "Эта стратегия станет основой \n для контент-плана и публикаций, \n отражающих ваши цели и предпочтения.",
      "proceed": "Перейти к опросу",
      "warning": "Очень важно максимально полно ответить на вопросы, чтобы стратегия была приближена к вашим ожиданиям.",
      "warning2": "В процессе нашего с Вами взаимодействия мы ее скорректируем, с нашей точки зрения, для более точной персонализации."
    },
    "assistant": {
      "header": "Ассистент",
      "title": "Ваш персональный ассистент",
      "emptyChat": "Нет сообщений",
      "assistantInfo": "Ассистент может допускать ошибки. Рекомендуем проверять важную информацию.",
      "helloMessage": "Здравствуйте!\nЯ Ваш ассистент и готов помочь в разных ситуациях: подготовить или ответить на письмо,\nподелиться последними новостями, рассказать шутку или даже сделать перевод. Я здесь, чтобы поддержать\nВас в работе, бизнесе и во многих других вопросах. Чем могу помочь?",
      "textToGeneratePostFromAssistantMessage": "Сгенерируй пост на основе этого сообщения с минимальными изменениями",
      "createPostFromThisMessageBtn": "Создать пост для LinkedIn на основе этого сообщения",
      "chatSettingsTitle": "Настройки чата",
      "useYourFile": "Использовать Ваш файл",
      "assistantWarningInfo": "Необходимо загрузить файл для доступа к данной функции",
      "assistantFilesInstructionTitle": "Инструкция для использования ассистента",
      "assistantFilesInstructionContent": `Вы можете предоставить информацию для ассистента, который изучит ее и будет использовать при взаимодействии с вами. Для этого выполните следующие шаги:
1. Подготовьте файл в формате Word (.doc или .docx), содержащий информацию, которую ассистент должен учитывать.
2. Если вы хотите добавить новые данные к уже имеющимся, внесите новые сведения в тот же файл, сохранив всю ранее добавленную информацию.
3. Загрузите файл через соответствующий раздел сервиса.
После обработки информации ассистент начнет использовать её в своих ответах и рекомендациях.`,
      "uploadFile": "Загрузить файл",
      "uploadNewFile": "Обновить файл",
      "yourHaveFile": "У Вас есть загруженный файл.",
      "confirm": "Подтвердить"
    },
    "profile":{
      "cancelDelete": "Отменить удаление",
      "accountWillBeDeleted": "Ваш аккаунт будет удалён",
      "recoverAccount": "Отменить удаление",
      "removeAccountBtn": "Удалить аккаунт",
      "yesRemoveAccount": "Да, удалить",
      "notRemoveAccount": "Нет, не удалять",
      "removeAccountTitle": "Вы действительно хотите удалить аккаунт?",
      "removingApproved": "Запрос на удаление аккаунта успешно отправлен!",
      "recoverApproved": "Запрос на отмену удаления аккаунта успешно отправлен!",
      "accountActionWait": "Подождите...",
      "removeAccountDescription": "Нажимая эту кнопку, вы подтверждаете, что ваши данные будут удалены безвозвратно через 14 дней. После этого восстановить ваши персональные данные и другую информацию будет невозможно.",
      "emptyReferrals": "Вы пока никого не приглашали!",
      "status-accepted": "Приглашён",
      "status-pending": "В ожидании...",
      "status-gifted": "Вознаграждение получено",
      "status-taken": "Приглашение отклонено в пользу другого",
      "close": "Закрыть",
      "yourReferrals": "Ваши рефералы",
      "reInvite": "Отправить заново",
      "inviteSent": "Приглашение по адресу {{email}} отправлено",
      "inviteError": "Произошла ошибка при отправке приглашения. Это может быть вызванно разными причинами, возможно, данный пользователь уже зарегистрирован.",
      "header": "Профиль",
      "assistDescription": "Получайте свежие новости и советы от продвинутого ассистента",
      "endSubscriptionDate": "Окончание действия подписки:",
      "endFreeSubscriptionDate": "Пробный период закончится:",
      "buySubscription": "Оформить подписку",
      "upgradeSubscription": "Обновить план",
      "extendSubscription": "Продлить подписку",
      "cancelSubscription": "Отменить подписку",
      "comingSoon": "Уже совсем скоро...",
      "soon": "Уже скоро...",
      "name": "Имя",
      "login": "Логин",
      "subscription": "Подписка до",
      "no": "Нет",
      "yes": "Да",
      "lang": "Язык",
      "timeZone": "Часовой пояс",
      "linkAccount": "Привязать аккаунт",
      "linkedInError": "Перепривяжите аккаунт",
      "inviteFriend": "Пригласи друга:",
      "invite": "Пригласить",
      "timeZoneModalTitle": "Часовые пояса",
      "inviteModalTitle": "Приведи друга и получи полмесяца бесплатно!",
      "timeZoneModalText": `
        Мы внедрили возможность выбора часового пояса (например, UTC+5) специально для вашего удобства, чтобы вы могли более точно настраивать время публикации своих отложенных постов. Это поможет лучше ориентироваться на аудиторию в нужном регионе и добиться максимального охвата.
        <br>
        Рекомендуемое время для публикаций в linkedIn:
        \t•\tПонедельник: с 8:00 до 10:00
        \t•\tВторник: с 9:00 до 12:00
        \t•\tСреда: с 10:00 до 12:00
        \t•\tЧетверг: с 9:00 до 11:00
        \t•\tПятница: с 9:00 до 12:00
        <br>
        Пример использования:
        Если вы находитесь в Казахстане (UTC+5) и хотите публиковать контент для аудитории в США (например, в Нью-Йорке, UTC-5), просто укажите соответствующий часовой пояс. Это позволит вашим постам появляться в 9:00 утра по времени Нью-Йорка, когда аудитория наиболее активна.
        <br>
        Основные страны и смещения по UTC:
        Азия
        \t•\tКазахстан (Алматы): UTC+5
        \t•\tКитай (Пекин): UTC+8
        \t•\tИндия (Нью-Дели): UTC+5:30
        \t•\tЯпония (Токио): UTC+9
        \t•\tЮжная Корея (Сеул): UTC+9
        \t•\tИндонезия (Джакарта): UTC+7
        Африка
        \t•\tЮжно-Африканская Республика (Йоханнесбург): UTC+2
        \t•\tЕгипет (Каир): UTC+2
        \t•\tНигерия (Лагос): UTC+1
        \t•\tКения (Найроби): UTC+3
        \t•\tМарокко (Рабат): UTC+0
        Латинская Америка
        \t•\tБразилия (Сан-Паулу): UTC-3
        \t•\tАргентина (Буэнос-Айрес): UTC-3
        \t•\tМексика (Мехико): UTC-6
        \t•\tКолумбия (Богота): UTC-5
        \t•\tЧили (Сантьяго): UTC-3
        Европа
        \t•\tВеликобритания (Лондон): UTC+0
        \t•\tГермания (Берлин): UTC+1
        \t•\tФранция (Париж): UTC+1
        \t•\tИталия (Рим): UTC+1
        \t•\tИспания (Мадрид): UTC+1
        Северная Америка
        \t•\tСША (Нью-Йорк, Восточное время): UTC-5
        \t•\tСША (Лос-Анджелес, Тихоокеанское время): UTC-8
        \t•\tКанада (Торонто): UTC-5
        \t•\tКанада (Ванкувер): UTC-8
        <br>
        Обратите внимание, что некоторые страны имеют несколько часовых поясов. Если вы работаете с аудиторией из другой страны, рекомендуем уточнить нужное смещение по UTC для оптимального времени публикации. 
        Проверяйте локальное время через сервисы вроде timeanddate.com.
        <br>
        Настройте свой часовой пояс и привлеките максимум внимания своей аудитории! 😊
      `,
      "inviteModalText": `
      Хочешь получить дополнительные дни пользования сервисом бесплатно?<br>
      Просто пригласи друга! Вот как это работает:
      <br>
      <ol>
      <li><b>1. Поделитесь контактами:</b> Укажите почту друга, которому может быть интересен наш сервис.</li>
      <li><b>2. Мы отправим письмо:</b> Мы напишем вашему другу письмо (вас укажем в копии), где расскажем о нашем сервисе и о том, что вы его пригласили. Так ваш друг точно будет знать, что это не спам или мошенничество.</li>
      <li><b>3. Получите полмесяца бесплатно:</b> Если друг зарегистрируется и оплатит платный тариф, вам автоматически начислят 15 дней бесплатного доступа к тому же тарифу, который выбрал ваш друг.</li>
      <li><b>4. Друг получает выгоду:</b> Ваш друг также получит <b>скидку 30% на первый месяц использования</b> сервиса. Здесь выигрывают все!</li>
      </ol>
      <br>
       Если у вас возникнут вопросы, свяжитесь с нами по адресу <a class='text-purple-800' href="mailto:info@imean.io">info@imean.io</a> — будем рады помочь!
      `,
    },
    "subscriptionPlans":{
      "header": "Тарифы",
      "Strategy": "Стратегии",
      "ContentPlan": "Контент планы",
      "LeadingQuestions": "Наводящие вопросы",
      "Post": "Посты",
      "Theme": "Темы",
      "PostImages": "Изображения постов",
      "free": "Бесплатный",
      "paid": "Премиум",
      "assistant": "Продвинутый ассистент",
      "infoButton": "Описание функционала",
      "modalTitle": "Функционал сервиса IMEAN.IO:",
      "modalText": `
        iMean поможет вам легко и эффективно создавать публикации для LinkedIn с помощью искусственного интеллекта. Наш сервис предлагает:
        - Создание постов с ИИ:
        Наш сервис использует передовые алгоритмы искусственного интеллекта, чтобы генерировать уникальный контент, который соответствует вашей профессиональной личности и целям. Вы получаете посты, адаптированные к вашему стилю, аудитории и ключевым темам вашей сферы деятельности. Это позволяет выделяться среди конкурентов и формировать личный бренд.
        - Мгновенный и Отложенный постинг:
        Вы можете публиковать свои посты на LinkedIn сразу или запланировать их на более удобное время. Планирование публикаций позволяет организовать регулярную активность на платформе, даже если вы заняты. Благодаря этой функции вы охватываете свою аудиторию в наиболее подходящее время, повышая эффективность и вовлеченность.
        - Ассистент-помощник:
        Наш интеллектуальный помощник готов ответить на ваши вопросы, помочь с формированием идей для публикаций, перевести текст на другой язык, подготовить деловые письма и даже подсказать стратегии взаимодействия с вашей аудиторией. Общайтесь с ним в формате диалога, как с личным консультантом, который всегда под рукой. Более того, вы можете попросить ассистента написать пост и сразу опубликовать его в LinkedIn, экономя ваше время и упрощая процесс публикации.
        - Голосовой ввод:
        Забудьте о необходимости печатать — просто говорите, и наш сервис преобразует ваши слова в текст. Эта функция особенно удобна, если у вас мало времени или вы хотите зафиксировать идею на ходу. Голосовой ввод работает быстро и точно, позволяя вам сосредоточиться на содержании, а не на наборе текста."
      `,
      "submit": "Понятно",
      "unlimitedRate":"Безлимитный функционал",
      "assistantRate": "Безлимитный функицонал + личный ИИ ассистент",
      "freeRate": "Бесплатный пробный период на 14 дней",
      "active": "Активный",
      "offerExpire": "Предложение закончится",
      "offerOver": "Предложение закончилось",
      "subscribeExpired": "Подписка закончилась",
    },
    "survey": {
        "survey": "Опрос",
        "recording": "Запись голоса.. Нажмите чтобы остановить запись",
        "type": "или напишите свой ответ здесь...",
        "wait": "Пожалуйста, подождите, мы сохраняем ваши ответы и генерируем информацию. ",
        "clear": "Очистить",
        "submit": "Далее",
        "tapToStart": "Нажмите, чтобы продиктовать Ваш ответ",

        "title": "Пожалуйста, заполните данные для создания контента",

        "inputLabelName": "Как вас зовут?",
        "inputPlaceholderName": "Введите Ваше имя",
        "inputInfoName": "Данное имя будет использоваться в контенте",

        "inputLabelIndustry": "Отрасль",
        "inputPlaceholderIndustry": "Отрасль в которой Вы работаете",
        "inputInfoIndustry": "Если Ваша отрасль отсутствует в списке, Вы можете ввести её вручную",

        "inputLabelPosition": "Должность",
        "inputPlaceholderPosition": "Ваша должность",
        "inputInfoPosition": "Если Ваша должность отсутствует в списке, Вы можете ввести её в ручную",

        "textLabelWorkExperienceAndSkills": "Опыт работы и ключевые навыки",
        "textPlaceholderWorkExperienceAndSkills": "Краткое описание опыта работы, ключевые навыки и компетенции, а также проекты, которыми Вы гордитесь.",

        "textLabelPersonalityHobby": "Личные интересы и увлечения",
        "textPlaceholderPersonalityHobby": "Информация о хобби и интересах, которые можно включить в посты, чтобы сделать их более человечными и аутентичными.",

        "textLabelSocialActivity": "Социальная активность и участие в сообществах",
        "textPlaceholderSocialActivity": "Информация о группах или сообществах, в которых Вы активно участвуете (например, профсоюзы, волонтерство, профессиональные группы).",

        "textLabelShortGoals": "Краткосрочные карьерные цели (на 1-2 года)",
        "textPlaceholderShortGoals": "Определение ближайших профессиональных целей, которые Вы хотите достичь в краткосрочной перспективе. Это может быть повышение в должности, освоение новых навыков или успешное завершение крупных проектов.",

        "textLabelLongGoals": "Долгосрочные карьерные цели (через 3-5 лет)",
        "textPlaceholderLongGoals": "В каком направлении Вы хотите развиваться в более длительной перспективе. Какие роли или позиции видите для себя в будущем. Эти цели помогают фокусироваться на стратегических шагах для достижения этих карьерных устремлений.",

        "textLabelUniqueTradeRelations": "Уникальное торговое предложение (УТП)",
        "textPlaceholderUniqueTradeRelations": "Чем Вы отличаетесь от других профессионалов в своей области. Это могут быть уникальные навыки, подходы или достижения, которые делают Вас ценным специалистом на рынке труда.",

        "textLabelAchievements": "Награды и достижения",
        "textPlaceholderAchievements": "Профессиональные награды, признания, сертификаты или похвалы от коллег и руководства, которые подчеркивают опыт и вклад в отрасль. Это может включать успешные проекты, лидерство или инновационные решения.",

        "textLabelProjects": "Проекты и инициативы в процессе реализации",
        "textPlaceholderProjects": "Информация о текущих проектах или инициативах, над которыми Вы работаете, и которые могут быть интересны для аудитории. Это показывает активность и вовлеченность в профессиональную среду.",

        "textLabelStrongSides": "Ваши уникальные сильные стороны",
        "textPlaceholderStrongSides": "Ключевые компетенции, сильные навыки и личные качества, которые помогают Вам успешно выполнять свою работу и преуспевать в своей профессиональной деятельности. Это может включать лидерские качества, умение решать сложные задачи, коммуникационные навыки и др.",

        "textLabelWeakSides": "Слабые стороны",
        "textPlaceholderWeakSides": "Те аспекты, которые Вы считаете своими зонами для роста или улучшения. Это могут быть пробелы в навыках или областях знаний, над которыми Вы хотите работать в будущем, чтобы стать более эффективным специалистом",

        "selectLabelExperience": "Уровень опыта",
        "selectPlaceholderExperience": "Выберите Ваш уровень опыта",

        "checkboxLabelThemes": "Темы для обсуждения",

        "checkboxLabelGoalsPublicLinkedIn": "Цели публикации на LinkedIn",
      },
      "home": {
        "welcomeBack": "Добро пожаловать, ",
        "logout": "Выйти",
        "support": "Техническая поддержка",
        "guideline": "Полезные советы",
        "contentPlan": "Создать контент-план",
        "personalAssistant": "Персональный ассистент",
        "strategy": "Цифровая стратегия",
        "createPost": "Создать публикацию",
        "formatPost": "Формат публикации",
        "linkSocials": "Привязать соц. сети",
        "profile": "Профиль",
        "personalBrandStrategy": "Публичный образ человека, ведущего профессиональную деятельность, который возникает в сознании целевой аудитории",
        "profileTitle": "Информация о профиле, Ваших подписках, приглашенных друзьях",
        "contentPlanTitle": "Здесь хранится информация о будущих постах",
        "createPostTitle": "Здесь вы можете быстро создать и сразу опубликовать пост",
        "assistantTitle": "В режиме диалога ваш личный помощник: перевести текст, написать письмо и многое другое",
        "strategyTitle": "Здесь создается стартегия для Вас",
        "subscriptionDaysLeft": "Количество дней до окончания подписки: {{days}}"
      },
      "personalBrand": {
        "needSurveyText": "Для более точного создания Вашей цифровой стратегии необходимо пройти опрос",
        "coffee": `Это может занять 5-7 минут.\r\nКак насчёт чашечки бодрящего кофе для вдохновения пока формируется стратегия?`,
        "header": "Цифровая стратегия",
        "newStrategy": "Создать новую стратегию",
        "Name": "Имя",
        "Occupation/Title": "Заголовок",
        "Short Bio": "Короткое описание",
        "Accolades & Awards": "Награды",
        "Strengths": "Сильные стороны",
        "Weaknesses": "Область для улучшения",
        "Passions": "Увлечения",
        "Values": "Ценности",
        "What Sets You Apart": "Что отличает вас",
        "Unique Benefits You Can Provide": "Уникальные преимущества, которые вы можете предоставить",
        "Determine Who You are Trying to Reach and Communicate With": "Целевая аудитория",
        "Understand Their Needs, Wants, and Challenges": "Понять их потребности, желания и проблемы»: «Понять их потребности, желания и проблемы",
        "How Should Daniel Address These Needs and Challenges": "Как {{username}} следует решать эти нужды и решать проблемы",
        "Colors": "Цвета",
        "Imagery": "Образы",
        "Personal Logo or Signature": "Личный логотип или подпись",
        "Areas of Expertise": "Области знаний",
        "Content Formats": "Форматы контента",
        "Content Themes": "возможные темы контента",
        "Engagement Strategy": "Стратегия взаимодействия",
        "Short-term Goals (1-2 years)": "Краткосрочные цели (1-2 года)",
        "Long-term Goals (3-5 years)": "Долгосрочные цели (3-5 лет)",
        "Feedback Channel": "Канал обратной связи",
        "Adaptation Strategy": "Стратегия адаптации",
        "Conclusion": "Заключение",
      },
      "sendPassword": {
        "header": "Забыли пароль",
        "title": "Пожалуйста, введите свой адрес электронной почты, чтобы получить код.",
        "placeholder": "Адрес электронной почты",
        "sendCode": "Отправить код"
      },
      "inputCode": {
        "header": "Входной код",
        "title": "Пожалуйста, введите код, отправленный на адрес электронной почты.",
        "placeholder": "Введите код",
        "proceed": "Продолжить"
      },
      "newPassword": {
        "noMatch": "Пароли не совпадают.",
        "header": "Создать новый пароль",
        "passwordRequirements": "Убедитесь, что ваш пароль содержит не менее 8 символов: 1 заглавную, 1 строчную, 1 цифру и 1 специальный символ.",
        "placeholderCreate": "Создать новый пароль",
        "placeholderConfirm": "Подтвердите пароль",
        "continue": "Продолжать"
      },
      "post": {
        "textToGenerateAnotherPost": "Напиши похожий либо продолжение к этому посту",
        "at": "в",
        "needSurveyText": "Для более точной генерации постов необходимо пройти опрос",
        "needSurveyRepeatText": "Для последующих генераций моментальных постов необходимо пройти опрос который поможет Вам генерировать более качественные посты",
        "leadingQuestions": "Наводящие вопросы",
        "noLeading1": "Для данного контент-плана нет наводящих вопросов",
        "noLeading2": "Нажмите «Создать», чтобы получить содержательные вопросы для идеального поста",
        "loading": "Загрузка...",
        "generate": "Генерировать",
        "headerPost": "Пост",
        "headerCreate": "Создать пост",
        "suggestion": "Предложения по посту",
        "published": "Опубликовано",
        "notpublished": "Не опубликован",
        "broken": "Ошибка",
        "planned": "Опубликуется:",
        "hashtagSuggestion": "Предложения по хештегам",
        "mediaSuggestion": "Предложения СМИ",
        "image": "Медиа файлы",
        "noImage": "Нет медиа файлов!",
        "noImageInfoNotDrag": "Перетащите файл(ы) сюда или нажмите для выбора",
        "noImageInfoWithDrag": "Отпустите для загрузки",
        "bigFileSize": "Слишком большой размер файла. Пожалуйста, выберите другое изображение до 10мб",
        "maxVideoSize": "Слишком большой размер файла. Пожалуйста, выберите другое видео до 300мб",
        "uploadImage": "Загрузить изображение",
        "save": "Сохранить",
        "processingImage": "Пожалуйста, подождите, мы обрабатываем изображение",
        "generateImage": "Создать изображение",
        "generatingImage": "Генерируем изображение",
        "generating": "Мы генерируем ваш пост",
        "pdf": "Скачать PDF",
        "remove": "Удалить",
        "addVideo": "Загрузить видео",
        "removeVideo": "Удалить видео",
        "removeImages": "Удалить изображения",
        "maxVideoDuration": "Максимальная длительность видео - 10 минут",
        "dragActive": "Перетащите файл(ы) сюда или нажмите для выбора",
        "onDrag": "Отпустите для загрузки",
        "dragInfo": "Вы можете загрузить медиа файлы: видео до 200мб и продолжительность до 10минут и изображения размером до 10мб",
      },
      "contentPlan": {
        "header": "Контент-план",
        "week": "Неделя",
        "add": "Нажмите + для выбора социальных сетей",
        "frequency": "Частота",
        "elementsTitle": "Выберите или добавьте элементы для контента этой недели",
        "custom": "Напишите здесь свои запросы на индивидуальный контент",
        "create": "Создать контент план",
        "tokenError": "LinkedIn не привязан",
        "strategyError": "У вас не сгенерирована личная стратегия",
        "needSurveyText":"Для создания контент плана необходимо пройти опрос",
        "goToSurveyBtnText":"Пройти опрос",
      },
      "functions": {
        "generate": "Создать",
        "generating": "Создание...",
        "successfullyGenerated": "Успешно создано!",
        "successfullyFinished": "Успешно завершено!",
        "successfullyUploaded": "Успешно загружено!",
        "successfullySendToGenerate": "Успешно начат процесс формирования!",
        "wrong": "Что-то пошло не так",
        "loading": "Загрузка...",
        "sending": "Отправка...",
        "microphone": "Ошибка доступа к микрофону. Проверьте свое устройство или дайте разрешение.",
        "generatingPost": "Пожалуйста, подождите, создается пост",
        "generatingContentPlan": "Пожалуйста, подождите, создается контент план",
        "loadingLeading": "Загрузка наводящих вопросов",
        "copied": "Скопировано!",
        "savingAnswersForStrategy": "Сохранение ответов и создание стратегии",
        "close": "Закрыть",
        "loggedIn": "Успешно авторизован!",
        "registered": "Успешная регистрация!",
        "incorrectCreds": "Неправильные учетные данные",
        "limitReached": "Достигнут лимит действий, использовано {{count}} из {{max}} генераций по вашему тарифу",
        "recoveryRequested": "Востановление пароля запрошенно",
        "newPassword": "Пароль изменен",
        "generatingBrand": "Пожалуйста, подождите, создается стратегия",
        "error": "Ошибка",
        "wrongLayout": "Неправильная раскладка, измените язык ввода",
        "maxImages": "Максимальное количество изображений - 6",
      },
      "socials": {
        "header": "Социальные сети",
        "linkAccounts": "Привяжите аккаунты социальных сетей для возможности авто-постинга"
      },
      "plancard": {
        "generated": "Пост сгенерирован",
        "notGenerated": "Пост не сгенерирован",
        "approved": "Одобрен",
        "notApproved": "Не одобрен",
        "publish": "Опубликовать пост",
        "approve": "Одобрить на публикацию",
        "disapprove": "Отменить публикацию",
      },
      "posts":{
        "header": "Посты",
        "generatePost": "Сгенерировать пост",
        "postDescription": "Поделитесь с нами основной идеей или контекстом планируемого поста. Вы можете воспользоваться микрофоном",
        "postDetails": "Подробности",
        "postDate": "Дата публикации",
        "publish": "Опубликовать",
        "regenerate": "Перегенерировать пост",
        "limit": "Лимит текущего тарифа исчерпан, вы можете оформить платную подписку, чтобы использовать сервис дальше",
        "goToRate": "Перейти к тарифам",
        "chooseFormatTitle": "Выберите формат публикации",
        "acceptFormat": "Подтвердить",
        "formatExampleText": "Пример",
        "createAnotherPost": "Создать похожий пост",
        "seePost": "Посмотреть пост"
      },
  "support": {
      "header": "Техническая поддержка",
      "sectionLabel": "Выберите раздел",
      "messageLabel": "Опишите проблему или предложение",
      "fileLabel": "Прикрепленные файлы",
      "send": "Отправить",
      "uploadFile": "Загрузить файл",
      "removeFile": "Удалить",
      "sections": {
        "home-page": "Главная страница",
        "profile-page": "Профиль",
        "content-plan": "Контент план",
        "publication": "Публикация",
        "personal-assistant": "Персональный помощник",
        "personal-branding": "Персональная стратегия",
      }
  },
      "hww":{
        "regTitle": "Регистрация в сервисе",
        "regSubtitle": "Зарегистрируйтесь в сервисе",
        "strategyTitle": "Создание стратегии",
        "strategySubtitle": "Пройдите опросник и ИИ предоставит персональную стратегию",
        "planTitle": "Создание контент плана",
        "planSubtitle": "С помощью стратегии ИИ создаст для Вас контент-план",
        "postTitle": "Публикация постов",
        "postSubtitle": "Опираясь на стратегию и контент-план, ИИ подберет оптимальные варианты постов"
      },
    "weekDays": {
      "Monday": "Понедельник",
      "Tuesday": "Вторник",
      "Wednesday": "Среда",
      "Thursday": "Четверг",
      "Friday": "Пятница",
      "Saturday": "Суббота",
      "Sunday": "Воскресенье"
    }
}
