import PageHeader from "../../components/Header/PageHeader";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SupportForm from "../../components/Forms/SupportForm";


const Support = () => {
	const navigate = useNavigate();
	const {t} = useTranslation();
	return (
			<div>
				<PageHeader
						title={t("support.header")}
						onBackClick={() => {
							navigate("/home");
						}}
				/>
				<div className='survey-page pb-5'>
				<SupportForm/>
				</div>
			</div>
	);
};

export default Support;