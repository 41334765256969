import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { history, fetchWrapper } from '../helpers';
import { getI18n } from 'react-i18next';
import i18n from "i18next";
import { jwtDecode } from 'jwt-decode'
import {getUserTimeInfo} from "../helpers/get-user-time-zone";
import {getUserCoordinates} from "../utils/getUserCoordinates";

const name = 'auth';

const createInitialState = () => {
    return {
        user: JSON.parse(localStorage.getItem('user')),
        locale: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).languageGeneration : "en",
        error: null
    }
}

const createReducers = () => {
    const logout = (state) => {
        state.user = null;
        localStorage.removeItem('user');
        history.navigate('/sign-in');
    }

    const updateUser = (state) => {
        const newInfo = JSON.parse(localStorage.getItem("user"))
        state.user.name = newInfo.name
        state.locale = newInfo.languageGeneration
    }

    return { logout, updateUser};
}

const createExtraActions = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/auth`;

    const login = () => {
        return createAsyncThunk(
            `${name}/login`,
            async ({ username, password }) => await fetchWrapper.post(`${baseUrl}/login`, { username, password })
        );
    }

    const register = () => {
        return createAsyncThunk(
            `${name}/register`,
            async ({ name, username, password, lang }) =>{
                await fetchWrapper.post(`${baseUrl}/register`, { name, username, password, lang })
                return await fetchWrapper.post(`${baseUrl}/login`, { username, password, lang })
            }
        );
    }
    const newPassword = () => {
        return createAsyncThunk(
            `${name}/new-password`,
            async ({ username, newPassword }) =>{
                return await fetchWrapper.post(`${baseUrl}/new-password`, { username, newPassword })
            }
        );
    }

    const oauth = () => {

        return createAsyncThunk(
            `${name}/oauth`,
            async ({code, redirectUrl, provider}) => {
                let coordinates = null;
                // try {
                //     coordinates = await getUserCoordinates();
                // } catch (e) {
                //     console.error(e)
                // }

                return await fetchWrapper.post(`${baseUrl}/login/${provider}`,
        {
                    code,
                    redirectUrl,
                    coordinates,
                    defaultUTC: getUserTimeInfo(),
                    defaultLanguageGeneration: localStorage.getItem('ln')
                }
                );
            }
        )
    }

    const recoveryRequest = () => {
        return createAsyncThunk(
            `${name}/recovery-request`,
            async ({username}) => {
                await fetchWrapper.post(`${baseUrl}/recovery-request`, {username})
            }
        )
    }

    const recoveryPassword = () => {
        return createAsyncThunk(
            `${name}/recovery-password`,
            async ({token, username}) => {
                await fetchWrapper.post(`${baseUrl}/recovery-password`, {token, username})
            }
        )
    }

    return {
        login: login(),
        register: register(),
        oauth: oauth(),
        recoveryRequest: recoveryRequest(),
        recoveryPassword: recoveryPassword(),
        newPassword: newPassword()
    };
}

const createExtraReducers = (builder) => {
    const login = () => {
        const { pending, fulfilled, rejected } = extraActions.login;

            builder.addCase(pending, (state) => {
                state.error = null;
            })
            builder.addCase(fulfilled, (state, action) => {
                const user = action.payload;
                user.languageGeneration = jwtDecode(user.accessToken).languageGeneration
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                state.locale = user.languageGeneration;
                i18n.changeLanguage(user.languageGeneration)
                message.success(getI18n().t("functions.loggedIn"));

                let from;

                // if (user.strategiesCount) {
                if (true) {
                    from = (history?.location?.state || { from: { pathname: '/home' } })?.from;
                }
                // else {
                //     from = (history?.location?.state || { from: { pathname: '/welcome' } })?.from;
                // }

                history.navigate(from);
            })
            builder.addCase(rejected, (state, action) => {
                console.log(state)
                state.error = action.error;
                message.error(getI18n().t("functions.incorrectCreds"));
            })
    }

    const oauth = () => {
        const { pending, fulfilled, rejected } = extraActions.oauth;

            builder.addCase(pending, (state) => {
                state.error = null;
            })
            builder.addCase(fulfilled, (state, action) => {
                const user = action.payload;

                // localStorage.setItem('user', JSON.stringify(user));
                // state.user = user;
                // message.success('Successfully fetched user, сохранение в процессе, как сделаю запушу');
                // console.log('user', user);

                // const { from } = history?.location?.state || { from: { pathname: '/home' } };
                // history.navigate(from);

                if(user){
                    user.languageGeneration = i18n.language
                    localStorage.setItem('user', JSON.stringify(user));
                    state.user = user;
                    let from;
    
                    // if (user.strategiesCount) {
                    if (isValidEmail(user.email)) {
                        message.success(getI18n().t("functions.loggedIn"));
                        from = (history?.location?.state || { from: { pathname: '/home' } })?.from;
                    } else {
                        message.error(getI18n().t("functions.incorrectCreds"));
                        from = { pathname: '/sign-in' };
                    }
                    // else {
                    //     from = (history?.location?.state || { from: { pathname: '/welcome' } })?.from;
                    // }
                    history.navigate(from);
                } else {
                    history.navigate({ pathname: '/profile' });
                }

            })
            builder.addCase(rejected, (state, action) => {
                state.error = action.error;
                message.error(getI18n().t("functions.incorrectCreds"));
            })
    }

    const register = () => {
        const { pending, fulfilled, rejected } = extraActions.register;
            builder.addCase(pending, (state) => {
                state.error = null;
            })
            builder.addCase(fulfilled, (state, action) => {
                const user = action.payload;

                user.languageGeneration = jwtDecode(user.accessToken).languageGeneration
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                state.locale = user.languageGeneration;
                i18n.changeLanguage(user.languageGeneration)
                message.success(getI18n().t("functions.registered"));
                const { from } = history?.location?.state || { from: { pathname: '/home' } };
                // const { from } = history?.location?.state || { from: { pathname: '/welcome' } };
                history.navigate(from);
            })
            builder.addCase(rejected, (state, action) => {
                message.error(getI18n().t("functions.incorrectCreds"));
                state.error = action.error;
            })
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    }

    const recoveryRequest = () => {
        const { pending, fulfilled, rejected } = extraActions.recoveryRequest;
            builder.addCase(pending, (state) => {
                state.error = null;
            })
            builder.addCase(fulfilled, (state, action) => {

                message.success(getI18n().t("functions.recoveryRequested"));
                // const { from } = history?.location?.state || { from: { pathname: '/welcome' } };
                // history.navigate(from);
            })
            builder.addCase(rejected, (state, action) => {
                message.error(getI18n().t("functions.incorrectCreds"));
                state.error = action.error;
            })
    }

    const recoveryPassword = () => {
        const { pending, fulfilled, rejected } = extraActions.recoveryPassword;
            builder.addCase(pending, (state) => {
                state.error = null;
            })
            builder.addCase(fulfilled, (state, action) => {
                const user = action.payload;

                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                message.success(getI18n().t("functions.recoveryRequested"));
                
            })
            builder.addCase(rejected, (state, action) => {
                message.error(getI18n().t("functions.incorrectCreds"));
                state.error = action.error;
            })
    }

    const newPassword = () => {
        const { pending, fulfilled, rejected } = extraActions.newPassword;
            builder.addCase(pending, (state) => {
                state.error = null;
            })
            builder.addCase(fulfilled, (state, action) => {
                const user = action.payload;

                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                message.success(getI18n().t("functions.newPassword"));
                const { from } = history?.location?.state || { from: { pathname: '/home' } };
                // const { from } = history?.location?.state || { from: { pathname: '/welcome' } };
                history.navigate(from);
            })
            builder.addCase(rejected, (state, action) => {
                message.error(getI18n().t("functions.incorrectCreds"));
                state.error = action.error;
            })
    }

    login()
    register()
    oauth()
    recoveryRequest()
    recoveryPassword()
    newPassword()
}

const extraActions = createExtraActions();
const slice = createSlice({
    name: name,
    initialState: createInitialState(),
    reducers: createReducers(),
    extraReducers: builder => {
        const extraReducers = createExtraReducers(builder);
    }
});

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;
