import { Select } from "antd";
import { useState, useEffect } from "react";
import i18n from "i18next";

export const LanguageHeader = () => {
    const [language, setLanguage] = useState();

    const setLanguageLocalStorage = (value) => {
        localStorage.setItem("ln", value);
      };

    const onChangeLanguage = (ln) => {
        setLanguage(ln);
        setLanguageLocalStorage(ln);
    };

    const getLanguageLocalStorage = () => {
      const lsLang = localStorage.getItem("ln");
      const navigatorLang = window.navigator.language.split('-')[0].toLowerCase();

      if (lsLang && (lsLang !== "undefined")) {
        return lsLang;
      }
      if (navigatorLang === 'ru') {
        return navigatorLang;
      }
      if (navigatorLang === 'en') {
        return navigatorLang;
      }

      return "en";
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    useEffect(() => {
      const navigatorLang = window.navigator.language.split('-')[0];
      const localStorageLn = getLanguageLocalStorage();

        if (!localStorageLn) {
            if (navigatorLang === 'ru' || navigatorLang === 'en') {
              setLanguageLocalStorage(navigatorLang || "ru");
              setLanguage(navigatorLang || "ru");
            } else {
              setLanguageLocalStorage("ru");
              setLanguage("ru");
            }
          } else {
            setLanguage(localStorageLn);
            localStorage.setItem('ln', localStorageLn);
          }
    }, [])

    return(
        // <div className="App">
        //     <div className="flex justify-end items-center rounded-b-lg w-full max-w-96 h-12 bg-violet-500 m-auto">
        //         <div>
        //             <Select
        //             onChange={onChangeLanguage}
        //             value={language}
        //             className="w-26 mr-2"
        //             >
        //             <Select.Option value="ru">Русский</Select.Option>
        //             <Select.Option value="en">English</Select.Option>
        //             </Select>
        //         </div>
        //     </div>
        // </div>
        <div className="App">
            <div className="flex justify-center items-center rounded-b-lg w-full max-w-96 h-12  m-auto">
                <div>
                    <Select
                    onChange={onChangeLanguage}
                    value={language}
                    className="w-26 mx-auto"
                    >
                    <Select.Option value="ru">Русский</Select.Option>
                    <Select.Option value="en">English</Select.Option>
                    </Select>
                </div>
            </div>
        </div>
    )
}