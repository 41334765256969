import PageHeader from "../../components/Header/PageHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import "./style.css";
import React, { useEffect, useState } from "react";
import { fetchWrapper } from "../../helpers";
import { Select } from "antd";
import { useDebounce } from "use-debounce";
import i18n from "i18next";
import { getProvidersUrls } from "../../utils/auth";
import { PROVIDER } from "../../constants/auth";
import xIcon from "../icons/linkedin-icon.svg";
import { authActions } from "../../store";
import { useDispatch } from "react-redux";
import moment from "moment";
import crownIcon from "../icons/crown.png";
import InviteFriend from "../../components/Forms/InviteFriend";
import ReferralsModal from "../../components/modals/Referrals-modal";
import axios from "axios";
import {getUserTimeInfo} from "../../helpers/get-user-time-zone";
import TimeZoneModal from "../../components/modals/TimeZone-modal";
import RemoveAccountModal from "../../components/modals/Remove-account-modal";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const ProfilePage = () => {
  const { t } = useTranslation();
  const [handleUpdateProfile, sethandleUpdateProfile] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((obj) => obj.auth.user);
  const [profile, setProfile] = useState(null);
  const [currentTimeZone, setCurrentTimeZone] = useState(getUserTimeInfo());
  const [timeZones, setTimeZones] = useState([]);
  const [language, setLanguage] = useState();
  const [name, setName] = useState(user.name);
  const [subDateEnd, setSubDateEnd] = useState();
  const [subLevel, setSubLevel] = useState();
  const [updateName] = useDebounce(name, 1000);
  const [handleUpdateReferrals, sethandleUpdateReferrals] = useState(false);
  const [socialMediaUsers, setSocialMediaUsers] = useState({
    facebook: {},
    instagram: {},
    linkedIn: {},
  });
  const [tokensIds, setTokensIds] = useState({
    facebook: "",
    instagram: "",
    linkedIn: "",
  });
  const [tokens, setTokens] = useState({
    facebook: false,
    instagram: false,
    linkedIn: false,
  });
  const [sid, setSid] = useState(
      jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken)
          .subscriptionLevel.id
  );
  const dispatch = useDispatch();
  const socialLogin = {
    linkedIn: () => {
      //   console.log(getProvidersUrls()[PROVIDER.LINKEDIN]);
      window.location = getProvidersUrls()[PROVIDER.LINKEDIN];
    },
  };

  const socialUnhook = {
    linkedIn: () => {
      fetchWrapper.get(`${baseUrl}/unhook/linkedin/${tokensIds.linkedIn}`).then((res) => {
        if (res) {
          setTokens(prevState => ({...prevState, linkedIn: false}));
          refreshToken();
        }
      });

    },
  };

  useEffect(() => {
    fetchWrapper
      .get(`${baseUrl}/auth/getSocialMediaTokens/`)
      .then((res) => {
        const socialUsers = {
          facebook: null,
          instagram: null,
          linkedIn: null,
        };
        const newTokensId = {
          facebook: "",
          instagram: "",
          linkedIn: "",
        };
        const filteredToken = {
          facebook: false,
          instagram: false,
          linkedIn: false,
        };
        res.forEach((token) => {
          if (token.socialMediaType === "FACEBOOK") {
            filteredToken.facebook = true;
            newTokensId.facebook = token.id;
            socialUsers.facebook = {username: token?.username, email: token?.email};
          }
          if (token.socialMediaType === "INSTAGRAM") {
            filteredToken.instagram = true;
            newTokensId.instagram = token.id;
            socialUsers.instagram = {username: token?.username, email: token?.email};
          }
          if (token.socialMediaType === "LINKEDIN") {
            // console.log(token)
            filteredToken.linkedIn = true;
            newTokensId.linkedIn = token.id;
            socialUsers.linkedIn = {username: token?.username, email: token?.email};
          }
        });
        setSocialMediaUsers(socialUsers);
        setTokens(filteredToken);
        setTokensIds(newTokensId);
      })
      .catch(() => {});
  }, []);
  const refreshToken = () => {
    fetchWrapper.get(`${baseUrl}/auth/refresh-token`).then((res) => {
      const oldUser = JSON.parse(localStorage.getItem("user"));
      oldUser.languageGeneration = language;
      oldUser.name = name;
      oldUser.accessToken = res.accessToken;
      localStorage.setItem("user", JSON.stringify(oldUser));
      setSubDateEnd(jwtDecode(res?.accessToken)?.userSubscription?.endDate);
      setSubLevel(jwtDecode(res?.accessToken)?.subscriptionLevel);
      dispatch(authActions.updateUser());
    });
    localStorage.setItem('ln', language);
  };

  const onChangeLanguage = () => {
    if (language === "ru") {
      setLanguage("en");
      localStorage.setItem('ln', "en");
    } else {
      setLanguage("ru");
      localStorage.setItem('ln', "ru");
    }
  };

  const onChangeTimeZone = (TZ) => {
    setCurrentTimeZone(TZ);
    fetchWrapper.post(`${baseUrl}/profile/update-info`,{
      timeZone: TZ
    });
  };

  useEffect(() => {
    refreshToken();

    axios.get('/data/utc-zones.json')
        .then((res)=>{
            setTimeZones(res.data)
        })
        .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/profile/`).then((res) => {
      setLanguage(res.languageGeneration);
      localStorage.setItem('ln', res.languageGeneration);
      setProfile(res);
      if (res.timeZone){
        setCurrentTimeZone(res.timeZone);
      }
    });
  }, [handleUpdateProfile]);

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('ln', language);
    fetchWrapper.post(`${baseUrl}/profile/update-info`, {
      languageGeneration: language,
    });
    refreshToken();
  }, [language]);

  useEffect(() => {
    fetchWrapper
      .post(`${baseUrl}/profile/update-info`, { name: updateName })
      .then(() => {
        refreshToken();
      });
  }, [updateName]);

  return (
    <>
      <PageHeader
        title={t("profile.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />
      <div className="profile">
        <div className="profile_info_container">
          <div
              style={{display: "flex", alignItems: "center", columnGap: "5px"}}
          >
            <p className="profile_info__text">
              <span className="profile_info_title">{t("profile.name")}:</span>
            </p>
            <input
                type="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            {!!sid ? <img className='premium-icon' src={`${crownIcon}`} alt="premium"/> : null}
          </div>
          <p className="profile_info__text">
            <span className="profile_info_title">{t("profile.login")}:</span>{" "}
            {user.email}
          </p>
          {subDateEnd && (
              <p className="profile_info__text">
              <span className="profile_info_title">
                {t("profile.subscription")}:
              </span>{" "}
                {moment(subDateEnd).format("DD/MM/YYYY")}
              </p>
          )}
          <div
              style={{display: "flex", alignItems: "center", columnGap: "5px"}}
          >
            <p className="profile_info__text">
              <span className="profile_info_title">{t("profile.lang")}:</span>
            </p>
            <Select
                onChange={onChangeLanguage}
                value={language}
                className="w-26 mr-2"
            >
              <Select.Option value="ru">Русский</Select.Option>
              <Select.Option value="en">English</Select.Option>
            </Select>
          </div>
          {!!timeZones.length && (
            <div
                style={{display: "flex", alignItems: "center", columnGap: "5px"}}
            >
              <p className="profile_info__text">
                <span className="profile_info_title">{t("profile.timeZone")}:</span>
              </p>
              <Select
                  onChange={onChangeTimeZone}
                  value={currentTimeZone}
                  className="w-26 mr-2"
                  options={timeZones}
              >
                {/*{timeZones?.map(({id, value}) => {*/}
                {/*  <Select.Option key={id} value={value}>{value}</Select.Option>*/}
                {/*})}*/}
              </Select>
              <TimeZoneModal/>
            </div>
          )}
          <p className="profile_info__text">
            <span className="profile_info_title">
              {t("profile.linkAccount")}:
            </span>
          </p>
          <button
              className="social-button x"
              onClick={() => tokens.linkedIn ? socialUnhook.linkedIn() : socialLogin.linkedIn()}
          >
            <span className="icon">
              <img width={60} src={xIcon} alt=""/>
            </span>
            {tokens.linkedIn
                ? <span className='text-xs w-full text-left flex flex-col items-start justify-start gap-0.5'>
                  <b className='text-sm'>
                    {t("signin.unlinkedLinkedIn")}
                  </b>
                  <span className='m-0 p-0'>
                  {socialMediaUsers?.linkedIn?.username.toLowerCase().includes('couldnt fetch') ? t("profile.linkedInError") : socialMediaUsers?.linkedIn?.username}
                  </span>
                  <span className='text-[10px] m-0 p-0'>
                  {socialMediaUsers?.linkedIn?.email}
                  </span>
                </span>
                : t("signin.linkLinkedIn")}
          </button>
          <p className="profile_info__text">
            <span className="profile_info_title">
              {subLevel?.id
                  ? t("profile.upgradeSubscription")
                  : t("profile.buySubscription")}
              :
            </span>
          </p>
          <button
              className="logout-button"
              // className="profile-button"
              onClick={() => navigate("/subscription-plans")}
          >
            {subLevel?.id
                ? t("profile.upgradeSubscription")
                : t("profile.buySubscription")}
          </button>

          <InviteFriend handleUpdate={sethandleUpdateReferrals}/>
          <ReferralsModal handleUpdate={handleUpdateReferrals}/>
          <RemoveAccountModal handleUpdate={sethandleUpdateProfile} profile={profile}/>
        </div>
      </div>
    </>
  );
};
