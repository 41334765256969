import {Button, message, Modal} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {fetchWrapper} from "../../helpers";

const baseUrl = `${process.env.REACT_APP_API_URL}`;


const InviteFriend = ({handleUpdate}) => {
	const [isLoading, setIsLoading] = useState(false);
	const {t} = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [invitedList, setInvitedList] = useState([]);

	const toggleModal = () => {
		setIsModalOpen(p => !p);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsLoading(true);
		let blocked = false;

		for (let i = 0; i < invitedList.length; i++){
			if (invitedList[i] === e.target.email.value){
				blocked = true;
			}
		}

		if (blocked){
			message.success(t("profile.inviteSent", {email: e.target.email.value}));
			e.target.email.value = '';
			return;
		}

		fetchWrapper.post(`${baseUrl}/invitation/invite`, {whom_invited: e.target.email.value})
				.then((res)=>{
					if (res){
						message.success(t("profile.inviteSent", {email: e.target.email.value}));
						setInvitedList(prevState => [...prevState, e.target.email.value]);
						e.target.email.value = '';
						const timeout = setTimeout(() => {
							handleUpdate(prevState => !prevState);
							clearTimeout(timeout);
						}, 500);
					}
					setIsLoading(false);
				}).catch((err)=>{
					console.error(err);
					message.error(t("profile.inviteError"));
					setIsLoading(false);
				})
	};

	return (
			<form onSubmit={handleSubmit} className='flex flex-col gap-2 border-t pt-2.5 mt-4'>
				<div className='flex justify-between items-center'>
					<h4 className='font-semibold'>{t("profile.inviteFriend")}</h4>
					<Modal
							title={t("profile.inviteModalTitle")}
							open={isModalOpen}
							onOk={toggleModal}
							onCancel={toggleModal}
							footer={[
								<Button key="submit" onClick={toggleModal} type="primary">
									{t("subscriptionPlans.submit")}
								</Button>,
							]}
					>
						<p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={ {__html: t("profile.inviteModalText")}}/>
					</Modal>
					<span onClick={toggleModal} className='flex justify-center items-center cursor-help bg-gray-300 hover:bg-gray-200 text-white h-5 w-5 rounded-full'>
						?
					</span>
				</div>
				<input disabled={isLoading} required type='email' name='email' className='border rounded-lg p-2' placeholder='email'/>
				<button disabled={isLoading}
						className='logout-button'
						// className='profile-button'
				>{t("profile.invite")}</button>
			</form>
	);
};

export default InviteFriend;