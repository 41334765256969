import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {mergeStateObject} from "../../utils/merger";
import {fetchWrapper} from "../../helpers";
import {useNavigate} from "react-router-dom";
import {message} from "antd";


const formDefaultValues = {
	userName: '',
	industry: '',
	position: '',
	levelExperience: '',
	topicsOfInterest: [],
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;


const SurveyIndustryAndPositionsForm = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [autocompleteValues, setAutocompleteValues] = useState({
		industries: [],
		positions: [],
		skills: [],
		themes: [],
	});
	const [surveyForm, setSurveyForm] = useState(formDefaultValues);

	const handleSubmitForm = (e) => {
		e.preventDefault();
		fetchWrapper.patch(`${baseUrl}/user-profile`, surveyForm).then(response => {
			if (response.message) {
				message.success(t("functions.successfullyGenerated"));
				navigate("/home");
			}
		}).catch((err)=>{
			console.error(err)
			message.error(t("functions.wrong"));
		});
	}

	const handleResetForm = ()=>{
		setSurveyForm(formDefaultValues)
	}

	useEffect(() => {
		fetchWrapper.get(`${baseUrl}/dictionaries/specializations`).then(response => {
			mergeStateObject(setAutocompleteValues, {industries: response})
		}).catch((err)=>{
			console.error(err)
		})
		fetchWrapper.get(`${baseUrl}/dictionaries/survey-skills`).then(response => {
			mergeStateObject(setAutocompleteValues, {skills: response})
		}).catch((err)=>{
			console.error(err)
		})
		fetchWrapper.get(`${baseUrl}/dictionaries/survey-themes`).then(response => {
			mergeStateObject(setAutocompleteValues, {themes: response})
		}).catch((err)=>{
			console.error(err)
		})
	}, []);
	return (
			<form onSubmit={handleSubmitForm}>

				<div className="question">
					<div className='mb-5'>
						<label className='font-semibold' htmlFor="name">{t('survey.inputLabelName')}</label>
						<input
								required
								id='name'
								className='w-full border rounded-lg px-2.5 py-2'
								placeholder={t("survey.inputPlaceholderName")}
								value={surveyForm.userName}
								onChange={e=>mergeStateObject(setSurveyForm, {userName: e.target.value})}
						/>
						<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>
					</div>

					<div className='my-5'>
						<label className='font-semibold' htmlFor="industry">{t("survey.inputLabelIndustry")}</label>
						<input
								required
								list='industry-list'
								id='industry'
								className='w-full border rounded-lg px-2.5 py-2'
								placeholder={t("survey.inputPlaceholderIndustry")}
								onChange={e => {
									if (!autocompleteValues.position && e.target.value.length > 2) {
										mergeStateObject(setAutocompleteValues, {
											positions: autocompleteValues.industries?.find(item => item?.label[i18n.language] === e.target.value)?.children
													||
													[{
														id: Math.random(),
														label: {
															ru: '',
															en: '',
														},
													}]
										})
									} else {
										mergeStateObject(setAutocompleteValues, {positions: []})
									}
									mergeStateObject(setSurveyForm, {industry: e.target.value})
								}}
						/>
						<datalist id="industry-list">
							{autocompleteValues.industries?.map((item, index) => (
									<option key={item?.id} value={item?.label[i18n.language]}/>
							))}
						</datalist>
						<small className='text-[12px] opacity-65 text-left w-full block'>{t("survey.inputInfoIndustry")}</small>
					</div>

					{!!autocompleteValues?.positions?.length &&
							<div className='my-5'>
								<label className='font-semibold' htmlFor="position">{t("survey.inputLabelPosition")}</label>
								<input
										required
										list='position-list'
										id='position'
										className='w-full border rounded-lg px-2.5 py-2'
										placeholder={t("survey.inputPlaceholderPosition")}
										onChange={e => mergeStateObject(setSurveyForm, {position: e.target.value})}
								/>
								<datalist id="position-list">
									{
										autocompleteValues?.positions?.map((item, index) => (
												<option key={item?.id} value={item?.label[i18n?.language]}/>
										))
									}
								</datalist>
								<small className='text-[12px] opacity-65 text-left w-full block'>{t("survey.inputInfoPosition")}</small>
							</div>
					}

					<div className='flex flex-col my-5'>
						<label className='font-semibold' htmlFor="experienceLevel">{t("survey.selectLabelExperience")}</label>
						<select
							value={surveyForm.levelExperience}
							className='cursor-pointer border rounded-lg px-2.5 py-2'
							id='experienceLevel'
							onChange={e=>mergeStateObject(setSurveyForm, {levelExperience: e.target.value})}
						>
							<option defaultChecked disabled value="">{t("survey.selectPlaceholderExperience")}</option>
							{autocompleteValues.skills.map((level) => (
									<option key={level.id} value={level[i18n.language]}>{level.label[i18n.language]}</option>
							))}
						</select>
					</div>

					<div className='text-left mt-5'>
						<p className='text-center font-semibold'>{t("survey.checkboxLabelThemes")}</p>
						{autocompleteValues.themes.map((theme) => (
								<div key={theme.id}>
									<input
										className='cursor-pointer'
										name={theme.label[i18n.language]}
										id={theme.label[i18n.language]}
										type="checkbox"
										onChange={e=> mergeStateObject(setSurveyForm, {topicsOfInterest: e.target.checked ? [...surveyForm.topicsOfInterest, theme.label[[i18n.language]]] : surveyForm.topicsOfInterest.filter((item) => item !== theme.label[[i18n.language]])})}
									/>
									<label
										className='ms-1.5 cursor-pointer'
										htmlFor={theme.label[i18n.language]}
									>
										{theme.label[i18n.language]}
									</label>
								</div>
						))}
					</div>

				</div>

				<div className='flex justify-center items-center'>
					<button
						type='reset'
						className="clear-button"
						onClick={handleResetForm}
					>
						{t("survey.clear")}
					</button>
					<button
						type='submit'
						className="submit-button"
					>
						{t("survey.submit")}
					</button>
				</div>
			</form>
	);
};

export default SurveyIndustryAndPositionsForm;