import {Button, Drawer, message, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import crownIcon from "../../pages/icons/crown.png";
import checkIcon from "../../pages/icons/check-icon-black.svg";
import takenIcon from "../../pages/icons/uncheck-icon.svg";
import {fetchWrapper} from "../../helpers";


const baseUrl = `${process.env.REACT_APP_API_URL}`;

const ReferralsModal = ({handleUpdate}) => {
	const {t} = useTranslation();
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [referrals, setReferrals] = useState([]);

	const handleResendInvite = (email) => {
		fetchWrapper.post(`${baseUrl}/invitation/invite`, {whom_invited: email})
				.then((res)=>{
					if (res){
						message.success(t("profile.inviteSent", {email: email}));
					}
				})
				.catch((err)=>{
					console.error(err)
					message.error(t("profile.inviteError"));
				})
	}

	useEffect(() => {

		fetchWrapper.get(`${baseUrl}/invitation/referrals`)
		.then((res) => {
			if (res) {
				setReferrals(res);
			}
		})
	}, [handleUpdate]);

	return (
			<div>
				<button
						type="button"
						className="w-full text-sm text-center p-2 py-2.5 border border-transparent rounded-xl text-gray-500 hover:text-gray-600 hover:border-gray-600 font-semibold"
						onClick={() => setDrawerVisible(true)}
				>
					{t("profile.yourReferrals")}
				</button>
				<Drawer
						title={t("profile.yourReferrals")}
						placement={'bottom'}
						closable={false}
						onClose={() => setDrawerVisible(false)}
						open={drawerVisible}
						classNames={{
							mask: 'backdrop-blur-sm',
							content: 'h-full max-w-[648px] mx-auto overflow-hidden rounded-t-2xl',
							wrapper: 'h-full max-w-[648px] mx-auto overflow-hidden rounded-t-2xl'
						}}
						styles={{
							wrapper:{maxWidth:'648px', margin:'0 auto', borderRadius: '2rem 2rem 0 0', maxHeight: '500px'},
						}}
						extra={
							<Space>
								<Button type="default" onClick={() => setDrawerVisible(false)}>
									{t("profile.close")}
								</Button>
							</Space>
						}
				>
					{referrals.length ?
						referrals?.map((item, index) => (
								<div key={index} className={`${item.status === "taken" && "opacity-50 select-none pointer-events-none"} flex items-center justify-between gap-2.5 border-b border-gray-200 py-2`}>
									<div className="flex items-center">
										<div className="pointer-events-none select-none bg-gradient-to-br from-gray-50 to-gray-200 text-purple-600 text-xl w-12 h-12 border-purple-400 border rounded-full flex justify-center items-center font-semibold overflow-hidden">
											{item.email[0].toUpperCase()}
											{item.email[1].toUpperCase()}
										</div>
										<div className="ml-3">
											<p className="text-sm font-medium text-gray-900">{item.email}</p>
											<p className="text-sm text-gray-500">
												{t(`profile.status-${item.status}`)}
											</p>
										</div>
									</div>
									<div className="flex items-center">
										{item.status === "pending" && (
											<button
													onClick={(e)=> {
														handleResendInvite(item.email)
														e.target.setAttribute('disabled', true);
													}}
													className="text-xs border border-indigo-500 py-0.5 px-1 rounded-xl font-medium text-indigo-600 hover:text-white hover:bg-indigo-600 disabled:border-gray-600 disabled:text-gray-600 disabled:bg-gray-50 disabled:cursor-not-allowed"
											>
												{t("profile.reInvite")}
											</button>
										)}
										{item.status === "taken" && (
												<img className='h-5 filter-bw' src={`${takenIcon}`} alt=""/>
										)}
										{item.status === "gifted" && (
												<img className='h-5 filter-bw' src={`${crownIcon}`} alt=""/>
										)}
										{item.status === "accepted" && (
												<img className='h-5 filter-bw' src={`${checkIcon}`} alt=""/>
										)}
									</div>
								</div>
						)):
							<h1 className='text-center text-2xl text-black/50 mt-10 pointer-events-none'>
								{t("profile.emptyReferrals")}
							</h1>
					}
				</Drawer>
			</div>
	);
};

export default ReferralsModal;