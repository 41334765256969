import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import { useTranslation } from "react-i18next";
import { fetchWrapper } from "../../helpers";
import { useNavigate } from "react-router-dom";
import activeMic from "../icons/mic-on.svg";
import inactiveMic from "../icons/mic-off.svg";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {message, notification, Space, Button, Drawer, Collapse, Checkbox} from "antd";
import { contentPlanActions, postActions, questionsActions } from "../../store";
import AudioRecorder from "audio-recorder-polyfill";
import i18n from "i18next";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const GeneratePost = () => {
  window.MediaRecorder = AudioRecorder;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postDescription, setPostDescription] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();
  const getInputDatetime = () => {
    const d = new Date();
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -5)
      .slice(0, -3);
  };
  const [date, setDate] = useState(() => getInputDatetime());
  const [sn, setSn] = useState("LinkedIn");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [contentFormats, setContentFormats] = useState([]);
  const [selectedContentFormatId, setSelectedContentFormatId] = useState(null);

  const contentPlan = useSelector((obj) => obj.contentPlan.contentPlan);

  const [postLoading, setPostLoading] = useState(false);
  const [isMicActive, setIsMicActive] = useState(false);

  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="primary"
          size="small"
          onClick={() => navigate("/subscription-plans")}
        >
          {t("posts.goToRate")}
        </Button>
      </Space>
    );
    notificationApi.open({
      message: t("functions.error"),
      description: t("posts.limit"),
      btn,
      key,
    });
  };

  const onSubmit = (e) => {
    setPostLoading(true);
    e.preventDefault();
    const event = new Date(date);
    fetchWrapper
      .post(`${baseUrl}/post/save`, {
        description: postDescription,
        socialNetwork: "LinkedIn",
        date: event.toISOString(),
        formatId: selectedContentFormatId
      })
      .then((res) => {
        navigate(`/create-post/${res.contentPlanId}`);
      })
      .catch((err) => {
        openNotification();
      });
  };

  const startRecording = () => {
    console.log(date);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setIsMicActive(true);
        const recorder = new MediaRecorder(stream);
        recorder.start();
        setMediaRecorder(recorder);
        recorder.addEventListener("dataavailable", (e) => {
          message.loading(t("functions.loading"));
          const audioBlob = new Blob([e.data], { type: "audio/wav" });
          const audioFile = new File([audioBlob], "recording.wav", {
            type: "audio/wav",
          });
          const formData = new FormData();
          formData.append("audio", audioFile);

          axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/upload-audio`,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          })
            .then(
              (data) =>
                data.data &&
                setPostDescription(
                  (postDescription) => `${postDescription || ""}${data.data}`
                )
            )
            .catch((error) => console.error(error));
        });
      })
      .catch((error) => {
        message.error(t("functions.microphone"));
      });
  };

  const stopRecording = () => {
    mediaRecorder?.stop();
    // You might want to do something with the audio chunks here, like creating a blob and saving or playing it
    const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
    // Resetting audio chunks after recording is stopped
    setAudioChunks([]);
    setIsMicActive(false);
    // Here, you could save the blob or do something else with it
  };

  useEffect(() => {
    return () => {
      mediaRecorder?.state === "recording" && mediaRecorder.stop();
    };
  }, [mediaRecorder]);

  const toggleMic = () => {
    if (isMicActive) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const onChangeTextarea = (e) => {
    setPostDescription(e.target.value);
    // if (
    //   i18n.language === "en" &&
    //   (new RegExp(/^[a-zA-Z0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
    //     e.target.value
    //   ) ||
    //     e.target.value === "")
    // ) {
    //   setPostDescription(e.target.value);
    // } else if (
    //   i18n.language === "ru" &&
    //   (new RegExp(/^[а-яА-Я0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
    //     e.target.value
    //   ) ||
    //     e.target.value === "")
    // ) {
    //   setPostDescription(e.target.value);
    // } else {
    //   messageApi.open({
    //     type: "error",
    //     content: t("functions.wrongLayout"),
    //   });
    // }
  };

  const onChangeFormatsCheckboxes = (e) => {
    e.stopPropagation();
    const { id } = e.target;

    if (selectedContentFormatId === id) {
      setSelectedContentFormatId(null);
    } else {
      setSelectedContentFormatId(id);
    }

  }

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/postformats`)
      .then((res) => {
        setContentFormats(res);
      });
  }, []);
  return (
    <>
      {contextHolder}
      {notificationContextHolder}
      <PageHeader title={t("posts.generatePost")} />
      <div ac className="generate-post">
        <div className="welcome-container" style={{ marginTop: "0" }}>
          <div className="main-content post-sn-container">
            <div className="flex gap-4 justify-center py-4 border-b border-solid border-b-[color:var(--Primary-200,#E9D7FE)]">
              {/* <div
              style={{ opacity: sn === "Facebook" ? 1 : 0.4 }}
              onClick={() => setSn("Facebook")}
              className="flex flex-col flex-1 font-medium"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8432315b7e3752ab28ffd9571f771342eef6b676c1f20e4b8a5e6ee48c70349?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                className="self-center w-6 aspect-square fill-indigo-800"
              />
              <div className="mt-1">Facebook</div>
            </div> */}
              <div
                style={{ opacity: sn === "LinkedIn" ? 1 : 0.4 }}
                onClick={() => setSn("LinkedIn")}
                className="flex flex-col flex-1"
              >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25408c5dff59b86cb5e75674b2a798a87cd455bde0adf0ceb8c9a670d1405e8?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                  className="self-center w-6 aspect-square"
                />
                <div className="mt-1">LinkedIn</div>
              </div>

              {/* <div
              style={{ opacity: sn === "Instagram" ? 1 : 0.4 }}
              onClick={() => setSn("Instagram")}
              className="flex flex-col flex-1"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/497038a4df4615b7ef35db19c49364c0fdcd7e3749f9681fe807b55de2426e88?apiKey=300ff06bbf3a4f5c9c73ca7577720bf1&"
                className="self-center w-6 aspect-square"
              />
              <div className="mt-1">Instagram</div>
            </div> */}
            </div>
          </div>
        </div>
        <form onSubmit={onSubmit} className="post-info-form" noValidate>
          <div className="survey-page">
            <label className="input-label text-center" htmlFor="description">
              {t("posts.postDescription")}
            </label>
            <div className="mic-icon" onClick={toggleMic}>
              <img src={isMicActive ? activeMic : inactiveMic} alt=""/>
            </div>
            {isMicActive && (
                <div className="text-center mt-4">{t("survey.recording")}</div>
            )}
            <div className="text-area-container">
              <textarea
                  placeholder={t("posts.postDescription")}
                  value={postDescription}
                  onChange={(e) => onChangeTextarea(e)}
                  name="description"
                  id="description"
                  maxLength={1000}
              />
              {!!selectedContentFormatId && (
                  <div className='text-center'>
                    <p className='font-semibold text-sm'>{t("home.formatPost")}</p>
                    <p className='text-xs'>{contentFormats.find((item) => item.id === selectedContentFormatId).name[i18n.language]}</p>
                  </div>
              )}

            </div>

            <div className='hidden'>
              <label className="input-label" htmlFor="description">
                {t("posts.postDate")}
              </label>
              <input
                  className="post-datepicker"
                  type="datetime-local"
                  id="date"
                  name="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
              />
            </div>

            <button
                type="submit"
                className="post-button"
                disabled={postLoading}
            >
              {t("home.createPost")}
            </button>

            {!!contentFormats.length && (
                <>
                  <button
                      type="button"
                      className="post-button mono mb-5"
                      disabled={postLoading}
                      onClick={() => setDrawerVisible(true)}
                  >
                    {t("home.formatPost")}
                  </button>

                  <Drawer
                      title={t("posts.chooseFormatTitle")}
                      placement={'bottom'}
                      closable={false}
                      onClose={() => setDrawerVisible(false)}
                      open={drawerVisible}
                      classNames={{
                        mask: 'backdrop-blur-sm',
                        content: 'h-full',
                        wrapper: 'h-full'
                      }}
                      extra={
                        <Space>
                          <Button type="primary" onClick={() => setDrawerVisible(false)}>
                            {t("posts.acceptFormat")}
                          </Button>
                        </Space>
                      }
                  >
                    <Collapse accordion defaultActiveKey={['1']}>
                      {contentFormats?.map((item) => (
                          <CollapsePanel extra={
                            <Checkbox
                                id={item.id}
                                value={item.name[i18n.language]}
                                onClick={e => e.stopPropagation()}
                                checked={selectedContentFormatId === item.id}
                                onChange={onChangeFormatsCheckboxes}
                            />
                          } header={item.name[i18n.language]} key={item.id}>
                            {Object?.keys(item?.structure).map((subItem, index) => (
                                <div key={index}>
                                  <b>+ {subItem}:</b>{' '}
                                  {item.structure[subItem][i18n.language]}
                                </div>
                            ))}
                            <p className='border-l-4 border-violet-700 rounded-r-lg bg-gray-100 p-4 mt-2.5'>
                              <b>{t("posts.formatExampleText")}:</b> {item.example[i18n.language]}
                            </p>
                          </CollapsePanel>
                      ))}
                    </Collapse>
                  </Drawer>
                </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
