import React, { useState } from 'react';
import {message, Modal} from 'antd';
import {useTranslation} from "react-i18next";
import {fetchWrapper} from "../../helpers";
import {useSelector} from "react-redux";
import EndDateTimer from "../Timers/EndDateTimer";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const RemoveAccountModal = ({handleUpdate, profile}) => {
	const user = useSelector((obj) => obj.auth.user);
	const {t} = useTranslation();
	const [openRemove, setOpenRemove] = useState(false);
	const [openRecover, setOpenRecover] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const showRecoverModal = () => {
		setOpenRecover(true);
	};

	const showDeleteModal = () => {
		setOpenRemove(true);
	};
	const handleOk = async () => {
		setIsLoading(true);

		if (openRecover) await recoverAccount();

		if (openRemove) await removeAccount();

		handleUpdate(p => !p);
		setOpenRemove(false);
		setIsLoading(false);
	};
	const handleCancel = () => {
		setOpenRemove(false);
		setOpenRecover(false);
	};

	const recoverAccount = async () => {
		let response = null;

		try {
			response = await fetchWrapper.post(`${baseUrl}/auth/recover-account`);
		} catch {
			console.error('Network error');
		}

		if(response?.message){
			message.success(t("profile.recoverApproved"));
		} else {
			message.error(t("functions.wrong"));
		}
	};

	const removeAccount = async () => {
		let response = null;

		try {
			response = await fetchWrapper.post(`${baseUrl}/auth/delete-account`);
		} catch {
			console.error('Network error');
		}

		if(response?.message){
			message.success(t("profile.removingApproved"));
		} else {
			message.error(t("functions.wrong"));
		}
	};


	if(profile?.isDeleted) {
		return (
				<div className='border-t pt-2.5 mt-5'>
					<p className='text-center text-xs text-red-700 my-2'>
						{t("profile.accountWillBeDeleted")}: {" "}
						<EndDateTimer
								currentDate={new Date()}
								endDate={profile?.requestDeleteAt}
						/>
					</p>

					<button
							type="button"
							className="text-xs w-full text-center p-2 py-2 border border-transparent rounded-xl text-gray-500/75 hover:text-red-600 hover:border-red-600/25 font-semibold disabled:opacity-50 disabled:border-transparent disabled:text-gray-600 disabled:cursor-not-allowed"
							onClick={showRecoverModal}
					>
						{t("profile.recoverAccount")}
					</button>

					<Modal
							open={openRecover}
							title={t("profile.removeAccountTitle")}
							onCancel={isLoading? null : handleCancel}
							footer={() => (
									<div className='flex gap-2 mt-5'>
										<button disabled={isLoading} onClick={handleCancel}
														className="w-full text-center p-2 py-2 border border-transparent rounded-xl text-gray-500 hover:text-gray-600 hover:border-gray-600 font-semibold"
										>
											{
												isLoading ? (
														t("profile.accountActionWait")
												): (
														t("profile.close")
												)
											}

										</button>
										<button disabled={isLoading} onClick={handleOk}
														className="w-full text-center p-2 py-2 border border-violet-600 bg-violet-600 rounded-xl text-white hover:bg-violet-700 hover:border-violet-700 font-semibold"
										>
											{
												isLoading ? (
														t("profile.accountActionWait")
												): (
														t("profile.cancelDelete")
												)
											}
										</button>
									</div>
							)}
					>
						<p>
							{t("profile.removeAccountDescription")}
						</p>
					</Modal>
				</div>
		);
	} else {
		return (
				<div className='border-t pt-2.5 mt-5'>

					<button
							type="button"
							className="text-xs w-full text-center p-2 py-2 border border-transparent rounded-xl text-gray-500/75 hover:text-red-600 hover:border-red-600/25 font-semibold disabled:opacity-50 disabled:border-transparent disabled:text-gray-600 disabled:cursor-not-allowed"
							onClick={showDeleteModal}
					>
						{t("profile.removeAccountBtn")}
					</button>

					<Modal
							open={openRemove}
							title={t("profile.removeAccountTitle")}
							onCancel={isLoading? null : handleCancel}
							footer={() => (
									<div className='flex gap-2 mt-5'>
										<button disabled={isLoading} onClick={handleOk}
														className="w-full text-center p-2 py-2 border border-transparent rounded-xl text-red-500 hover:text-red-600 hover:border-red-600 font-semibold"
										>
											{
												isLoading ? (
													t("profile.accountActionWait")
												): (
														t("profile.yesRemoveAccount")
												)
											}

										</button>
										<button disabled={isLoading} onClick={handleCancel}
														className="w-full text-center p-2 py-2 border border-gray-500 bg-gray-500 rounded-xl text-white hover:bg-gray-600 hover:border-gray-600 font-semibold"
										>
											{
												isLoading ? (
														t("profile.accountActionWait")
												): (
														t("profile.notRemoveAccount")
												)
											}
										</button>
									</div>
							)}
					>
						<p>
							{t("profile.removeAccountDescription")}
						</p>
					</Modal>
				</div>
		);
	}
};

export default RemoveAccountModal;