import {mergeStateObject} from "../../utils/merger";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {fetchWrapper} from "../../helpers";
import {message} from "antd";

const sections = [
'home-page',
'profile-page',
'content-plan',
'publication',
'personal-assistant',
'personal-branding',
];

const formDefaultValues = {
	section: 'home-page',
	message: '',
	files: [],
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const SupportForm = () => {
	const fileInputRef = useRef(null);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [files, setFiles] = useState([]);
	const [supportForm, setSupportForm] = useState(formDefaultValues);

	const handleSubmitForm = (e) => {
		e.preventDefault();
		if (!supportForm.message) return;

		const formData = new FormData();
		formData.append('section', supportForm.section);
		formData.append('message', supportForm.message);
		if (supportForm.files.length > 0) {
			supportForm.files.forEach((file) => formData.append('files', file));
		}
		fetchWrapper.post(`${baseUrl}/support`, formData).then(response => {
			if (response.message) {
				message.success(t("functions.successfullyGenerated"));
				navigate("/home");
			}
		}).catch((err)=>{
			console.error(err)
			message.error(t("functions.wrong"));
		});
	}

	const handleFileChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			setFiles((prevFiles) => {
				const newFiles = Array.from(e.target.files);
				const totalFiles = [...prevFiles, ...newFiles];
				return totalFiles.slice(0, 6);
			});
		}
	}

	const handleDeleteFile = (index) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	}
	const handleResetForm = ()=>{
		setSupportForm(formDefaultValues)
	}

	useEffect(() => {
		mergeStateObject(setSupportForm, {files});
	}, [files]);

	return (
			<form onSubmit={handleSubmitForm}>

				<div className="question">

					<div className='my-3'>
						<label className='block font-semibold'
									 htmlFor="section">{t('support.sectionLabel')}</label>
						<select value={supportForm.section}
										onChange={e => mergeStateObject(setSupportForm, {section: e.target.value})} id='section'
										className='my-5 border rounded-lg px-2.5 py-2 cursor-pointer'>
							{
								sections.map((section) => (
										<option key={section} value={section}>{t(`support.sections.${section}`)}</option>
								))
							}
						</select>
					</div>

					<div className='my-3'>
						<label className='font-semibold '
									 htmlFor="message">{t('support.messageLabel')}</label>
						<textarea
								required
								id='message'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("support.messageLabel")}
								value={supportForm.message}
								onChange={e => mergeStateObject(setSupportForm, {message: e.target.value})}
						/>
					</div>

					<div className='my-3'>
						<label className='font-semibold '
									 htmlFor="fileInput">{t('support.fileLabel')}
						</label>
						<div className='bg-purple-100 rounded-2xl'>
							{!!supportForm.files.length && (
									<div className='my-5'>
										{supportForm.files.map((file, index) => (
												<div className='m-2 border border-white rounded-2xl overflow-hidden' key={index}>
													{
														file.type.includes('image') ? (
																<img className='mx-auto' src={URL.createObjectURL(file)} alt={file.name} />
														) : (
																<video className='mx-auto' src={URL.createObjectURL(file)} controls />
														)
													}
													<div className='flex justify-between items-center p-2'>
														<p className='text-xs text-nowrap text-ellipsis overflow-hidden'>
														{file.name}
														</p>
														<button className='bg-red-500 rounded-2xl text-xs px-2 py-1 text-white' type='button' onClick={() => handleDeleteFile(index)}>
															{t("support.removeFile")}
														</button>
													</div>
												</div>
										))}
									</div>
							)}
						</div>
						<button type='button' onClick={()=> fileInputRef.current.click()} className="post-content-button mono mx-auto text-sm" style={{width: 'auto'}}>
							{t("support.uploadFile")}
						</button>
						<input
								multiple
								type="file"
								id='fileInput'
								className='hidden'
								ref={fileInputRef}
								onChange={handleFileChange}
								accept='video/mp4, video/mov, video/wmv, video/avi, video/flv, video/mpeg, video/mpg, video/quicktime, video/3gpp, video/webm, image/jpeg, image/png, image/jpg'
						/>
					</div>

				</div>

				<div className='flex gap-2 justify-center items-center'>
					<button
							type='reset'
							className="post-content-button danger"
							onClick={handleResetForm}
					>
						{t("survey.clear")}
					</button>
					<button
							type='submit'
							className="post-content-button"
					>
						{t("support.send")}
					</button>
				</div>
			</form>
	);
};

export default SupportForm;