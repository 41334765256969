import React, {useEffect, useState} from 'react';
import {mergeStateObject} from "../../utils/merger";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {fetchWrapper} from "../../helpers";
import {message} from "antd";
import axios from "axios";


const formDefaultValues = {
	experienceAndCases: '',
	personalInterests: '',
	socialActivityAndCommunities: '',
	goalPublishLinkedIn: [],
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const PersonalityExperienceForm = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [autocompleteValues, setAutocompleteValues] = useState({
		goalPublishLinkedIn: [],
	});
	const [surveyForm, setSurveyForm] = useState(formDefaultValues);

	const handleSubmitForm = (e) => {
		e.preventDefault();
		fetchWrapper.patch(`${baseUrl}/user-profile`, surveyForm).then(response => {
			if (response.message) {
				message.success(t("functions.successfullyGenerated"));
				navigate("/home");
			}
		}).catch((err)=>{
			console.error(err)
			message.error(t("functions.wrong"));
		});
	}

	const handleResetForm = ()=>{
		setSurveyForm(formDefaultValues)
	}

	useEffect(() => {
		axios.get('/data/personality-goals.json')
			.then(response => {
			mergeStateObject(setAutocompleteValues, {goalPublishLinkedIn: response.data});
		}).catch((err)=>{
			console.error(err)
		})
	}, []);
	return (
			<form onSubmit={handleSubmitForm}>

				<div className="question">
					<div className='my-5'>
						<label className='font-semibold ' htmlFor="workExperienceAndSkills">{t('survey.textLabelWorkExperienceAndSkills')}</label>
						<textarea
								required
								id='workExperienceAndSkills'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderWorkExperienceAndSkills")}
								value={surveyForm.experienceAndCases}
								onChange={e => mergeStateObject(setSurveyForm, {experienceAndCases: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

					<div className='my-5'>
						<label className='font-semibold ' htmlFor="personalityHobby">{t('survey.textLabelPersonalityHobby')}</label>
						<textarea
								required
								id='personalityHobby'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderPersonalityHobby")}
								value={surveyForm.personalInterests}
								onChange={e => mergeStateObject(setSurveyForm, {personalInterests: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

					<div className='my-5'>
						<label className='font-semibold ' htmlFor="socialActivity">{t('survey.textLabelSocialActivity')}</label>
						<textarea
								required
								id='socialActivity'
								className='min-h-24 max-h-96 w-full border rounded-lg px-2.5 py-2 placeholder:text-xs'
								placeholder={t("survey.textPlaceholderSocialActivity")}
								value={surveyForm.socialActivityAndCommunities}
								onChange={e => mergeStateObject(setSurveyForm, {socialActivityAndCommunities: e.target.value})}
						/>
						{/*<small className='text-[12px] opacity-65 text-left w-full block'>{t('survey.inputInfoName')}</small>*/}
					</div>

					<div className='text-left mt-5'>
						<p className='text-center font-semibold'>{t("survey.checkboxLabelGoalsPublicLinkedIn")}</p>
						{autocompleteValues.goalPublishLinkedIn.map((theme) => (
								<div key={theme.id}>
									<input
											className='cursor-pointer'
											name={theme.label[i18n.language]}
											id={theme.label[i18n.language]}
											type="checkbox"
											onChange={e => mergeStateObject(setSurveyForm, {goalPublishLinkedIn: e.target.checked ? [...surveyForm.goalPublishLinkedIn, theme.label[[i18n.language]]] : surveyForm.goalPublishLinkedIn.filter((item) => item !== theme.label[[i18n.language]])})}
									/>
									<label
											className='ms-1.5 cursor-pointer'
											htmlFor={theme.label[i18n.language]}
									>
										{theme.label[i18n.language]}
									</label>
								</div>
						))}
					</div>

				</div>

				<div className='flex justify-center items-center'>
					<button
							type='reset'
							className="clear-button"
							onClick={handleResetForm}
					>
						{t("survey.clear")}
					</button>
					<button
							type='submit'
							className="submit-button"
					>
						{t("survey.submit")}
					</button>
				</div>
			</form>
	);
};

export default PersonalityExperienceForm;