export function countdownTimer({ endDate, currentDate, finalMessage, setter, locale = 'ru' }) {
	const end = new Date(endDate);
	let now = new Date(currentDate);
	const localeTimesLabels = {
		hours: {
			ru: "ч.",
			en: "h.",
		},
		minutes: {
			ru: "м.",
			en: "m.",
		}
	}

	const intervalLink = setInterval(() => {
		now = new Date();
		const remainingTime = end - now;

		if (remainingTime <= 0) {
			clearInterval(intervalLink);
			setter({
				message: finalMessage,
				intervalLink,
			});
			return;
		}

		const seconds = Math.floor((remainingTime / 1000) % 60);
		const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
		const hours = Math.floor((remainingTime / 1000 / 60 / 60) % 24);
		const days = Math.floor(remainingTime / 1000 / 60 / 60 / 24);

		let formattedTime;

		if (remainingTime > 72 * 60 * 60 * 1000) {
			const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });
			const daysText = rtf.format(days, 'day');
			const hoursText = hours > 0 ? ` ${hours}${localeTimesLabels.hours[locale]}` : '';
			const minutesText = minutes > 0 ? ` ${minutes}${localeTimesLabels.minutes[locale]}` : '';
			formattedTime = `${daysText}${hoursText}${minutesText}`;
		} else {
			formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
		}

		setter({
			message: formattedTime,
			intervalLink,
		});
	}, 1000);

	setter({
		message: '',
		intervalLink,
	});
}
